import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { getToken } from "../utils/token";
import { FlooringType } from "../types";
import { getFlooringTypes as fetchFlooringTypes } from "../api/flooringTypes";

type TProps = {
  children: ReactNode;
};

type FlooringTypesContextValue = {
  flooringTypes: FlooringType[];
  setFlooringTypes: (ft: FlooringType[]) => void;
  getFlooringTypes: () => Promise<void>;
  clearFlooringTypes: () => void;
  error: any;
  fetched: boolean;
};

export const FlooringTypesContext = createContext<FlooringTypesContextValue>({
  flooringTypes: [],
  setFlooringTypes: () => undefined,
  getFlooringTypes: async () => undefined,
  clearFlooringTypes: () => undefined,
  error: null,
  fetched: false,
});

const FlooringTypesProvider = ({ children }: TProps) => {
  const [flooringTypes, setFlooringTypes] = useState<FlooringType[]>([]);
  const [error, setError] = useState<any>(null);
  const [fetched, setFetched] = useState(false);

  const updateFlooringTypesArray = useCallback(async (newFT: FlooringType[]) => {
    setFlooringTypes((prev) => {
      const diffFloorings = newFT.filter(c => prev.findIndex(x => x.id === c.id) === -1);

      if (diffFloorings.length === 0) {
        return prev;
      }

      return [...prev, ...diffFloorings];
    });
  }, []);

  const getFlooringTypes = useCallback(async () => {
    const { data, error } = await fetchFlooringTypes();

    setFetched(true);

    if (error) {
      setError(error.error);
      setFlooringTypes([]);
      return;
    }

    if (data) {
      setFlooringTypes(data);
    }
  }, []);

  const clearFlooringTypes = useCallback(() => {
    setFlooringTypes([]);
    setError(null);
    setFetched(false);
  }, []);

  useEffect(() => {
    const tokenInStorage = getToken();

    if (tokenInStorage && !fetched) {
      getFlooringTypes()
    }
  }, [fetched, getFlooringTypes]);

  const value = useMemo(
    () => ({
      flooringTypes,
      error,
      fetched,
      setFlooringTypes: updateFlooringTypesArray,
      getFlooringTypes,
      clearFlooringTypes,
    }),
    [flooringTypes, updateFlooringTypesArray, getFlooringTypes, clearFlooringTypes, error, fetched]
  );

  return <FlooringTypesContext.Provider value={value}>{children}</FlooringTypesContext.Provider>;
};

export default FlooringTypesProvider;
