import React from 'react'

const LoadingScreen = () => {
  return (
    <div className="text-mainWhite">
      <h1>Loading...</h1>
    </div>
  )
}

export default LoadingScreen