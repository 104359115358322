import axios, { AxiosError } from "axios";
import { getHeaders } from "./utils/getHeader";
import { Length } from "../types"; 

type GetLengthsResultWithError = {
  data?: Length[];
  error?: any;
};

export const fetchLengths = async (): Promise<GetLengthsResultWithError> => {
  let result: GetLengthsResultWithError = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/fields/field-length-options`,
      {
        headers: getHeaders(true),
      }
    );

    result.data = response.data as Length[];
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching lengths:", error);
    result.error = response?.data;
  }

  return result;
};
