import axios, { AxiosError } from "axios";
import { getHeaders } from "./utils/getHeader";
import { PricePerHour } from "../types/pricePerHour";

type GetPricesResultWithError = {
  data?: PricePerHour[];
  error?: any;
};

export const fetchPrices = async (): Promise<GetPricesResultWithError> => {
  let result: GetPricesResultWithError = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/fields/field-prices`,
      {
        headers: getHeaders(true),
      }
    );

    result.data = response.data as PricePerHour[];
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching prices:", error);
    result.error = response?.data;
  }

  return result;
};
