import { useEffect, useMemo } from "react";

import { toast } from "react-toastify";
import { Width, MenuItem } from "../../types";
import DropdownMenu from "../DropdownMenu";
import { useWidth } from "../../hooks/useWidth";

type TProps = {
  selectedWidth: MenuItem<Width> | null;
  setSelectedWidth: (option: MenuItem<Width>) => void;
  width?: number;
  height?: number;
  placeholder?: string;
  error?: string;
  showIcon?: boolean;
};

const WidthDropdown = ({
  selectedWidth,
  setSelectedWidth,
  ...rest
}: TProps) => {
  const { widths, error } = useWidth();

  const widthOptions = useMemo(() => {
    return widths.map((w) => {
      const item = {
        label: `${w.value}`,
        value: w,
        onClick: () => setSelectedWidth(item),
      };
      return item;
    });
  }, [widths, setSelectedWidth]);

  useEffect(() => {
    if (error) {
      toast(error.error, {
        toastId: "fetch-widths-error",
        theme: "dark",
        className: "text-center",
        bodyClassName: "custom-toast-body",
      });
    }
  }, [error]);

  return (
    <DropdownMenu<Width>
      items={widthOptions}
      selectedOption={selectedWidth}
      setSelectedOption={setSelectedWidth}
      {...rest}
    />
  );
};

export default WidthDropdown;
