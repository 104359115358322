import axios, { AxiosError } from "axios";
import { getToken } from "../utils/token";
import { DeleteOwnerFieldResult } from "../types/fields";

type DeleteOwnerFieldResultWithError = {
  data?: DeleteOwnerFieldResult;
  error?: any;
};

export const deleteField = async (complexId: number, id: number) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${complexId}/fields/${id}`;

  let result: DeleteOwnerFieldResultWithError = {};

  try {
    const response = await axios.delete(
      url,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    );

    result.data = response.data as DeleteOwnerFieldResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error deleting field:", error);
    result.error = response?.data;
  }

  return result;
};