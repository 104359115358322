import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useMemo } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import StatusBadge from "../../StatusBadge";
import { getDateForCalendar } from "../../../utils/dates";
import { Reservation } from "../../../types/reservations";
import { COMMON_TRANSLATE_KEYS } from "../../../constants/translate-keys";

import "dayjs/locale/en";
import "dayjs/locale/bg";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./index.css";

interface ReservationEvent {
  start: Date;
  end: Date;
  status: "CONFIRMED" | "DECLINED" | "PENDING";
  reservation: Reservation;
  onShowDetailsClick?: (reservation: Reservation) => void;
}

interface EventCalendarProps {
  complexName: string | undefined;
  fieldName: string | undefined;
  reservations: Reservation[];
  fieldId: string | undefined;
  complexId: string | undefined;
  onShowDetailsClick?: (reservation: Reservation) => void;
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  openingHours: string;
  closingHours: string;
}

const CustomEvent = ({ event }: { event: ReservationEvent }) => {
  let borderColor: string;

  switch (event.status) {
    case "CONFIRMED":
      borderColor = "border-mainGreen";
      break;
    case "DECLINED":
      borderColor = "border-functionalRed";
      break;
    case "PENDING":
      borderColor = "border-mainBlue";
      break;
  }

  return (
    <div
      className={`flex flex-col justify-center items-center h-full rounded border bg-additionalBlack cursor-pointer ${borderColor}`}
      onClick={() => event.onShowDetailsClick && event.onShowDetailsClick(event.reservation)}
    >
      <StatusBadge status={event.status} className="gap-1 max-w-36" style={{ margin: 0 }} />
    </div>
  );
};

const CustomToolbar = ({
  localizer,
  currentDate,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {
  localizer: any;
  currentDate: Date;
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
}) => {
  const handleNextWeek = useCallback(() => {
    setStartDate(dayjs(startDate).add(1, "w").toDate());
    setEndDate(dayjs(endDate).add(1, "w").toDate());
  }, [startDate, endDate, setStartDate, setEndDate]);

  const handlePrevWeek = useCallback(() => {
    setStartDate(dayjs(startDate).subtract(1, "w").toDate());
    setEndDate(dayjs(endDate).subtract(1, "w").toDate());
  }, [startDate, endDate, setStartDate, setEndDate]);

  return (
    <div className="py-2">
      <div className="flex items-center justify-center">
        <button onClick={handlePrevWeek}>
          <ChevronLeftIcon className="w-5 h-5 text-white" />
        </button>
        <label className="cursor-pointer pb-[1px]">
          {dayjs(startDate).format("MMM DD")} -{" "}
          {dayjs(endDate).format("MMM DD")}
        </label>
        <button onClick={handleNextWeek}>
          <ChevronRightIcon className="w-5 h-5 text-white" />
        </button>
      </div>
    </div>
  );
};

const EventCalendar: React.FC<EventCalendarProps> = ({
  complexName,
  fieldName,
  reservations,
  fieldId,
  complexId,
  onShowDetailsClick,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  openingHours,
  closingHours,
}) => {
  const { t, i18n } = useTranslation();

  const localizer = useMemo(() => {
    dayjs.locale(i18n.language);
    return dayjsLocalizer(dayjs);
  }, [i18n.language]);

  const formats = {
    timeGutterFormat: (date: Date, culture: any, localizer: any) => {
      return localizer.format(date, "HH:mm", culture);
    },
    dayFormat: (date: Date, culture: any, localizer: any) => {
      return dayjs(date).format("dddd M.DD");
    },
  };

  const events: ReservationEvent[] = useMemo<ReservationEvent[]>(
    () => {
      const mapped = reservations.map((reservation) => ({
        start: getDateForCalendar(reservation.start),
        end: getDateForCalendar(reservation.end),
        status:
          reservation.status,
        reservation: reservation,
        onShowDetailsClick: onShowDetailsClick ? () => onShowDetailsClick(reservation) : undefined
      }));

      
      return mapped.reduce<ReservationEvent[]>((prev, next, i, arr) => {
        const sameDateEventIndex = prev.findIndex((x) => dayjs(x.start).isSame(dayjs(next.start), 'date') && dayjs(x.start).isSame(dayjs(next.start), 'hour'));

        if (sameDateEventIndex === -1) {
          prev.push(next);
        } else {
          const sameDateElements = arr.filter(x => dayjs(x.start).isSame(next.start));

          prev[sameDateEventIndex] = sameDateElements.find(x => x.status === "CONFIRMED" || x.status === "PENDING") || sameDateElements[sameDateElements.length - 1];
        }


        return prev;
      }, []);
    },
    [reservations, onShowDetailsClick]
  );

  const earliestHour = useMemo(() => {
    const [hours, minutes] = openingHours.split(':');

    const dateWithOpeningHours = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      +hours,
      +minutes,
    )

    return dateWithOpeningHours;
  }, [openingHours, startDate]);

  const latestHour = useMemo(() => {
    const [hours, minutes] = closingHours.split(':');

    const dateWithClosingHours = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      +hours,
      +minutes,
    );

    return dateWithClosingHours;
  }, [closingHours, startDate]);

  return (
    <div className="p-4 bg-mainBlack text-mainWhite shadow-lg rounded-lg h-full">
      <h1 className="text-white">
        <Link to="/my-fields" className="pointer text-gray-500">
          {t(COMMON_TRANSLATE_KEYS.COMPLEXES)} /
        </Link>
        <Link
          to={`/arena/${complexId}/info`}
          className="pointer text-gray-500"
        >
          {complexName} ({t(COMMON_TRANSLATE_KEYS.FIELDS)}) /
        </Link>
        <Link
          to={`/arena/${complexId}/field-reservation/${fieldId}`}
          className="pointer text-gray-500"
        >
          {fieldName} /
        </Link>
        {t(COMMON_TRANSLATE_KEYS.RESERVATIONS)}
      </h1>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100%' }}
        views={["week"]}
        defaultView="week"
        date={startDate}
        min={earliestHour}
        max={latestHour}
        formats={formats}
        components={{
          event: CustomEvent,
          toolbar: () => (
            <CustomToolbar
              localizer={localizer}
              currentDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
            />
          ),
        }}
      />
    </div>
  );
};

export default EventCalendar;
