import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import Divider from "../../../components/Divider";
import InputField from "../../../components/InputField";
import Button from "../../../components/buttons/Button";
import { ReactComponent as Pencil } from "../../../icons/svg/ci_edit.svg";
import { useAuth } from "../../../hooks/useAuth";
import { profileSchema } from "../../../validation/schemas/profile";
import { toBase64 } from "../../../utils/files";
import { updateOwnerDetails } from "../../../api/owner";
import { COMMON_TRANSLATE_KEYS } from "../../../constants/translate-keys";
import { getTimeAgoText } from "../../../utils/dates";

interface IFormInput {
  name: string;
  email: string;
  phone:  string;
  password?: string;
  image?: string;
}

const EditProfileTab = () => {
  const [showForm, setShowForm] = useState(false);

  const changeImgRef = useRef<HTMLInputElement>(null);

  const { user, updateUser } = useAuth();

  const { t } = useTranslation();

  const { register, handleSubmit, reset, formState: { isValid, errors } } = useForm<IFormInput>({
    defaultValues: {
      name: user?.name || '',
      email: user?.email || '',
      phone: user?.phone || '',
      password: '',
    },
    resolver: yupResolver(profileSchema),
    mode: 'all',
  });

  const firstName = useMemo(() => user?.name.split(' ')[0] || '', [user]);

  const lastName = useMemo(() => user?.name.split(' ')[1] || '', [user]);

  const imageUrl = useMemo(() => user?.imageUrl || null, [user]);

  const email = useMemo(() => {
    if (user) {
      const [emailFirstPart, emailSecondPart] = user.email.split('@');
      const hiddenFirstPart = emailFirstPart.substring(0, 2) + '***';

      return `${hiddenFirstPart}@${emailSecondPart}`;
    }

    return '';
  }, [user]);

  const phone = useMemo(() => {
    if (user && user.phone) {
      const userPhone = user.phone;
      const phoneFirstPart = userPhone.substring(0, 2);
      const phoneLastPart = userPhone.substring(userPhone.length - 3);

      return `${phoneFirstPart}****${phoneLastPart}`;
    }

    return '';
  }, [user]);

  const password = useMemo(() => {
    const passLastChanged = user?.passwordLastUpdated;

    if (passLastChanged) {
      return `${t(COMMON_TRANSLATE_KEYS.UPDATED_PASSWORD)} ${getTimeAgoText(passLastChanged, true)}`;
    }

    return t(COMMON_TRANSLATE_KEYS.NO_PASSWORD_SET);
  }, [user?.passwordLastUpdated, t]);

  const toggleForm = () => {
    setShowForm(prev => !prev);
  }

  const handleImageUpload = async (files: FileList | null) => {
    if (files && files.length > 0) {

      const imageFile = files[0];
  
      const base64Image = await toBase64(imageFile);

      const { data, error } = await updateOwnerDetails({
        image: base64Image,
      });

      if (error) {
        toast(error.error.message, {
          toastId: 'image-upload-error',
        });
        return;
      }

      if (data) {
        updateUser(data);
      }
    }
  }

  const handleFormSubmit = async (formData: IFormInput) => {
    const {
      name: changedName,
      phone: changedPhone,
      email: changedEmail,
      password: changedPass,
    } = formData;

    const payload = {
      name: changedName,
      phone: changedPhone,
      imageUrl: user?.imageUrl,
      email: changedEmail,
      password: changedPass || undefined,
    };

    const { data, error } = await updateOwnerDetails(payload);

    if (error) {
      toast(error.error.message, {
        toastId: 'update-owner',
      });
      return;
    }

    if (data) {
      updateUser(data);
      toggleForm();
      reset({
        name: data.name,
        phone: data.phone,
        email: data.email,
      });
    }
  }

  const renderUserDetailsTableContent = useCallback(() => {
    const tableData = [
      {
        label: t(COMMON_TRANSLATE_KEYS.FIRST_NAME),
        value: firstName,
      },
      {
        label: t(COMMON_TRANSLATE_KEYS.LAST_NAME),
        value: lastName,
      },
      {
        label: 'Divider', // Used only to divide the form fields
        value: 'divider',
      },
      {
        label: t(COMMON_TRANSLATE_KEYS.PASSWORD),
        value: password,
      },
      {
        label: t(COMMON_TRANSLATE_KEYS.EMAIL),
        value: email,
      },
      {
        label: t(COMMON_TRANSLATE_KEYS.PHONE_NUMBER),
        value: phone,
      },
    ];

    return tableData.map(({ label, value }, i) => i === 2 ? (
      <tr key={`${label} ${value}`}>
        <td colSpan={3}>
          <Divider mb={4} mt={4} color="bg-gray-700" width={'100%'} />
        </td>
      </tr>
    ) : (
      <tr key={`${label} ${value}`}>
        <td className="py-3">
          <p>{label}</p>
        </td>
        <td className="py-3">
          <p className="text-ellipsis overflow-hidden">{value}</p>
        </td>
        <td className="py-2 flex justify-end">
          <button className="text-mainBlue" onClick={() => toggleForm()}>{t(COMMON_TRANSLATE_KEYS.CHANGE)}</button>
        </td>
      </tr>
    ));
  }, [t, firstName, lastName, password, email, phone]);

  const renderProfilePicture = useCallback(() => {
    if (imageUrl) {
      return (
        <img
          src={imageUrl}
          alt="profile"
          className="rounded-full border border-gray-400 w-[100px] h-[100px] object-cover"
          width={36}
          height={36}
        />
      );
    }

    return (
      <div className="rounded-full border border-gray-400 w-[100px] h-[100px] bg-mainBlack"></div>
    );
  }, [imageUrl]);

  const renderForm = useCallback(() => {
    const tableData: {
      label: string;
      value: keyof IFormInput;
      disabled?: boolean;
      type?: string;
      autoComplete?: string;
      isRequired?: boolean;
    }[] = [
      {
        label: t(COMMON_TRANSLATE_KEYS.NAME),
        value: 'name',
        isRequired: true,
      },
      {
        label: 'Divider', // Used only for dividing the form elements
        value: 'name',
      },
      {
        label: t(COMMON_TRANSLATE_KEYS.PASSWORD),
        value: 'password',
        type: 'password',
        autoComplete: "new-password",
      },
      {
        label: t(COMMON_TRANSLATE_KEYS.EMAIL),
        value: 'email',
        isRequired: true,
      },
      {
        label: t(COMMON_TRANSLATE_KEYS.PHONE),
        value: 'phone',
        isRequired: true,
      },
    ];

    return tableData.map(({ label, value, isRequired, ...rest }, i) => i === 1 ? (
      <tr key={`${label} ${value}`}>
        <td colSpan={3}>
          <Divider mb={4} mt={4} color="bg-gray-700" width={'100%'} />
        </td>
      </tr>
    ) : (
      <tr key={`${label} ${value}`}>
        <td className="py-3">
          <p>{label} {isRequired ? <span className="text-orange-400">*</span> : null}</p>
        </td>
        <td colSpan={2} className="py-3">
          <InputField {...register(value)} error={t(errors[value]?.message || '')} {...rest} />
        </td>
      </tr>
    ));
  }, [register, errors, t]);

  return (
    <div className="w-2/5 flex flex-col gap-8">
      <div className="flex justify-center">
          <div className="rounded-full relative" onClick={() => changeImgRef.current?.click()}>
          {renderProfilePicture()}
          <Pencil
            className="absolute right-0 top-1/2 cursor-pointer"
            style={{ transform: 'translate(-15%, 100%)' }}
          />
          <input ref={changeImgRef} type="file" accept="image/*,.pdf" className="hidden" onChange={async (e) => await handleImageUpload(e.target.files)} />
        </div>
      </div>
      <table>
        <tbody>
          {showForm ? renderForm() : renderUserDetailsTableContent()}
        </tbody>
      </table>
      {showForm && (
        <div className="flex gap-3">
          <Button
            backgroundColor="bg-mainGreen"
            textColor="text-black-300"
            isDisabled={!isValid}
            onClick={handleSubmit(handleFormSubmit)}
          >
            {t(COMMON_TRANSLATE_KEYS.SAVE)}
          </Button>
          <Button
            backgroundColor="bg-cancel"
            textColor="text-black-300"
            onClick={() => {
              reset();
              toggleForm();
            }}
          >
            {t(COMMON_TRANSLATE_KEYS.CANCEL)}
          </Button>
        </div>
      )}
    </div>
  )
}

export default EditProfileTab;