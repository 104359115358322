/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef } from "react";

interface ButtonProps {
  href: string;
  width?: number;
  height?: number;
  backgroundColor?: string;
  textColor?: string;
  border?: string;
  text?: string;
  img?: string;
  type?: "button" | "submit" | "reset" | undefined;
  isDisabled?: boolean,
}

const LinkButton: React.FC<ButtonProps> = ({
  href,
  width = 20,
  height = 2.5,
  backgroundColor = "bg-mainBlack",
  textColor = "text-mainBlue",
  border = "",
  type="button",
  text,
  img,
  isDisabled = false,
}) => {
  const aTagRef = useRef<HTMLAnchorElement>(null);

  const borderClass = border ? `${border} border` : "";

  return (
    <button
      disabled={isDisabled}
      className={`${isDisabled ? "cursor-not-allowed opacity-50 " : ""}px-4 py-2 rounded-lg ${backgroundColor} ${textColor} ${borderClass}`}
      style={{ width: `${width}rem`, height: `${height}rem`, textAlign: "center" }}
      onClick={() => aTagRef.current?.click()}
      type={type}
    >
      {text ? text : (
        <img src={img} alt="No text or image prop on button" className="mx-auto" />
      )}
      <a ref={aTagRef} href={href} className="hidden" />
    </button>
  );
};

export default LinkButton;

