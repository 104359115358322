import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import DropdownMenu from "../components/DropdownMenu";
import InputField from "../components/InputField";
import Button from "../components/buttons/Button";
import { MenuItem, Complex, Field } from "../types";
import SwitchButton from "../components/SwitchButton";
import MyDrawer from "../components/Drawer";
import DateGridCalendar from "../components/calendar/date-calendar";
import TimeGridCalendar from "../components/calendar/time-calendar";
import dayjs from "dayjs";
import { getToken } from "../utils/token";
import Divider from "../components/Divider";
import { toast } from "react-toastify";
import { useAvailableReservations } from "../hooks/useAvailableReservations";
import { useTranslation } from "react-i18next";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";

interface IFormInput {
  otherPersonName?: string;
  otherPersonPhone?: string;
  time: string;
  date: string;
}

const BookField: React.FC = () => {
  const { complexId, fieldId } = useParams<{
    complexId: string;
    fieldId: string;
  }>();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset: resetFormValues,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    defaultValues: {
      otherPersonName: "",
      otherPersonPhone: "",
      time: "",
      date: "",
    },
  });

  const [complexes, setComplexes] = useState<Array<Complex>>([]);
  const [fields, setFields] = useState<Array<Field>>([]);
  const [selectedComplex, setSelectedComplex] =
    useState<MenuItem<Complex> | null>(null);
  const [selectedField, setSelectedField] = useState<MenuItem<Field> | null>(
    null
  );
  const [isForAnotherPerson, setIsForAnotherPerson] = useState<boolean>(false);
  const [isDateDrawerOpen, setIsDateDrawerOpen] = useState(false);
  const [isTimeDrawerOpen, setIsTimeDrawerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [fieldImages, setFieldImages] = useState<Array<string>>([]);

  const {
    fetch: fetchAvailableReservations,
    getDates,
    getTimeSlotsForDate,
  } = useAvailableReservations();

  const fetchReservationSlots = useCallback(
    async (fieldIdArg?: number, monthArg?: number, yearArg?: number) => {
      let selectedFieldId;

      if (fieldIdArg) {
        selectedFieldId = fieldIdArg;
      } else if (selectedField) {
        selectedFieldId = selectedField.value.id;
      } else {
        return;
      }

      let month = monthArg;
      let year = yearArg;

      if (!month || !year) {
        month = dayjs().month();
        year = dayjs().year();
      }

      await fetchAvailableReservations(selectedFieldId, month, year);
    },
    [fetchAvailableReservations, selectedField]
  );

  const closeDrawer = () => {
    setIsDateDrawerOpen(false);
    setIsTimeDrawerOpen(false);
  };

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
    setValue("date", dayjs(date).format("YYYY-MM-DD"), { shouldValidate: true });
    closeDrawer();
  };

  const handleTimeSelect = (time: string) => {
    setSelectedTime(time);
    setValue("time", time, { shouldValidate: true });
    closeDrawer();
  };

  const fetchComplexes = async (page: number = 1) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes?page=${page}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      setComplexes(response.data);
    } catch (error) {
      console.error("Error fetching complexes:", error);
    }
  };

  const fetchFields = async (
    complexId: number,
    page: number = 1,
    limit: number = 50
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${complexId}/fields?page=${page}&limit=${limit}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );

      setFields(response.data.fields);
    } catch (error) {
      console.error("Error fetching fields:", error);
    }
  };

  const handleComplexClick = (option: MenuItem<Complex>) => {
    setSelectedComplex(option);
  };

  const handleFieldClick = useCallback(
    async (option: MenuItem<Field>) => {
      setSelectedField(option);
      setFieldImages(option.value.imageUrls || []);

      if (option.value.isUnavailable) {
        toast("Field is unavailable", {
          toastId: "field-unavailable",
          theme: "dark",
          className: "text-center",
          bodyClassName: "custom-toast-body",
        });
        return;
      }

      const month = selectedDate?.getMonth() || undefined;
      const year = selectedDate?.getFullYear() || undefined;

      await fetchReservationSlots(option.value.id, month, year);
    },
    [fetchReservationSlots, selectedDate]
  );

  const onSubmit = async (data: IFormInput) => {
    if (!selectedField) {
      toast.error("Please select a field.");
      return;
    }

    if (!selectedDate) {
      toast.error("Please select a date.");
      return;
    }

    if (!data.date) {
      setError("date", { message: "Please select a date." });
      setIsDateDrawerOpen(true);
      return;
    }

    if (!data.time) {
      setError("time", { message: "Please select a time." });
      setIsTimeDrawerOpen(true);
      return;
    }

    const [start, end] = data.time.split("-");
    const formData = {
      fieldId: selectedField.value.id,
      date: data.date,
      startTime: start,
      endTime: end,
      isForAnotherPerson,
      name: isForAnotherPerson ? data.otherPersonName : undefined,
      phone: isForAnotherPerson ? data.otherPersonPhone : undefined,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/reservations`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
            "Accept-Language": "en",
          },
        }
      );

      toast.success("Reservation created successfully!", {
        theme: "dark",
        className: "text-center",
        bodyClassName: "custom-toast-body",
      });

      await fetchAvailableReservations(
        formData.fieldId,
        selectedDate.getMonth(),
        selectedDate.getFullYear(),
        true
      );

      resetForm();
      navigate(`/arena/${complexId}/field-reservation/${fieldId}`);
    } catch (error: any) {
      if (
        error?.response?.data?.error?.startsWith(
          "Reservation must be made between the following hours:"
        )
      ) {
        setError("time", { message: error?.response?.data?.error });
        return;
      }

      if (error?.response?.data?.error?.startsWith("Date has")) {
        setError("date", { message: error?.response?.data?.error });
        return;
      }

      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error, {
          theme: "dark",
          className: "text-center",
          bodyClassName: "custom-toast-body",
        });
        return;
      }

      toast.error("Something went wrong!", {
        theme: "dark",
        className: "text-center",
        bodyClassName: "custom-toast-body",
      });
    }
  };

  const resetForm = () => {
    resetFormValues();
    setSelectedComplex(null);
    setSelectedField(null);
    setIsForAnotherPerson(false);
    setSelectedDate(null);
    setSelectedTime(null);
    setFieldImages([]);
  };

  const handleCancel = () => {
    resetForm();
    navigate(`/arena/${complexId}/field-reservation/${fieldId}`);
  };

  const complexOptions: Array<MenuItem<Complex>> = complexes.map(
    (complex: Complex) => {
      const option = {
        label: complex.name,
        value: complex,
        onClick: () => {},
      };

      option.onClick = () => handleComplexClick(option);

      return option;
    }
  );

  const fieldOptions: Array<MenuItem<Field>> = fields.map((field: Field) => {
    const option: MenuItem<Field> = {
      label: field.name,
      value: field,
      onClick: () => {},
    };

    option.onClick = () => handleFieldClick(option);
    return option;
  });

  const filteredDates = useMemo(() => {
    if (selectedField?.value?.isUnavailable) {
      return [];
    }

    return getDates(selectedField?.value?.id);
  }, [selectedField, getDates]);

  const timeSlots = useMemo(() => {
    if (!selectedDate) {
      return [];
    }

    const slots = getTimeSlotsForDate(selectedField?.value?.id, selectedDate);

    return slots;
  }, [selectedField, selectedDate, getTimeSlotsForDate]);

  useEffect(() => {
    fetchComplexes();
  }, []);

  useEffect(() => {
    if (complexId && complexId.length > 0 && !selectedComplex) {
      const complexOption = complexOptions.find((option) => {
        return option.value.id.toString() === complexId;
      });
      if (complexOption) {
        setSelectedComplex(complexOption);
      }
    }
  }, [complexOptions, complexId, selectedComplex]);

  useEffect(() => {
    if (selectedComplex) fetchFields(selectedComplex.value.id);
  }, [selectedComplex]);

  useEffect(() => {
    if (fieldId && fieldId.length > 0 && !selectedField) {
      const fieldOption = fieldOptions.find((option) => {
        return option.value.id.toString() === fieldId;
      });
      if (fieldOption) {
        setSelectedField(fieldOption);

        const { imageUrls, id, isUnavailable } = fieldOption.value;

        setFieldImages(imageUrls || []);
        fetchReservationSlots(id);

        if (isUnavailable) {
          toast("Field is unavailable", {
            theme: "dark",
            className: "text-center",
            bodyClassName: "custom-toast-body",
          });
        }
      }
    }
  }, [fieldOptions, fieldId, selectedField, fetchReservationSlots]);

  return (
    <div className="mx-auto mb-4 mt-8">
      <div className="w-full h-64 mb-4">
        <img
          src={fieldImages.length > 0 ? fieldImages[0] : "/arena-arda.png"}
          alt={t(COMMON_TRANSLATE_KEYS.FIELD)}
          className="object-cover w-full h-full rounded-2xl"
          style={{ width: "608px", height: "256px" }}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4">
          <p className="text-mainWhite mt-4 mb-2">
            {t(COMMON_TRANSLATE_KEYS.CHOOSE_A_COMPLEX)}{" "}
            <span className="text-functionalRed">*</span>
          </p>
          <div className="flex flex-row justify-between">
            <DropdownMenu<Complex>
              items={complexOptions}
              selectedOption={selectedComplex}
              setSelectedOption={handleComplexClick}
              showIcon={false}
              width={38}
            />
          </div>
        </div>

        <div className="mt-4">
          <p className="text-mainWhite mt-4 mb-2">
            {t(COMMON_TRANSLATE_KEYS.CHOOSE_A_FIELD)}{" "}
            <span className="text-functionalRed">*</span>
          </p>
          <div className="flex flex-row justify-between">
            <DropdownMenu<Field>
              items={fieldOptions}
              selectedOption={selectedField}
              setSelectedOption={handleFieldClick}
              showIcon={false}
              width={38}
            />
          </div>
        </div>

        <div className="mt-8 flex flex-row justify-between">
          <SwitchButton
            width={38}
            isSelected={isForAnotherPerson}
            onClick={() => setIsForAnotherPerson(!isForAnotherPerson)}
          >
            {t(COMMON_TRANSLATE_KEYS.RESERVE_FOR_ANOTHER_PERSON)}
          </SwitchButton>
        </div>

        {isForAnotherPerson && (
          <div className="mt-4">
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.NAME_OF_THE_PERSON)}{" "}
              <span className="text-functionalRed">*</span>
            </p>
            <div className="flex flex-col justify-between">
              <InputField
                placeholder=""
                width={38}
                {...register("otherPersonName", {
                  required: {
                    value: isForAnotherPerson,
                    message: "Name is required",
                  },
                })}
                error={errors.otherPersonName?.message}
              />
            </div>
            <div className="mt-4">
              <p className="text-mainWhite">
                {t(COMMON_TRANSLATE_KEYS.PHONE_OF_THE_PERSON)}
                <span className="text-functionalRed">*</span>
              </p>
              <div className="flex flex-col justify-between">
                <InputField
                  placeholder=""
                  width={38}
                  {...register("otherPersonPhone", {
                    required: {
                      value: isForAnotherPerson,
                      message: "Phone is required",
                    },
                  })}
                  error={errors.otherPersonPhone?.message}
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-row justify-between gap-6">
          <div className="w-full mt-4">
            <p className="text-mainWhite mb-2">
              {t(COMMON_TRANSLATE_KEYS.DATE)}
              <span className="text-functionalRed">*</span>
            </p>
            <InputField
              placeholder={t(COMMON_TRANSLATE_KEYS.SELECT_DATE)}
              width={18}
              {...register("date", {
                required: { value: true, message: "Date is required" },
              })}
              error={errors.date?.message}
              onClick={() => setIsDateDrawerOpen(true)}
              readOnly
              value={
                selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD") : ""
              }
              color="text-mainBlue"
            />
          </div>
          <div className="w-full">
            <p className="text-mainWhite mt-4 mb-2">
              {t(COMMON_TRANSLATE_KEYS.TIME)}
              <span className="text-functionalRed">*</span>
            </p>
            <InputField
              placeholder={t(COMMON_TRANSLATE_KEYS.SELECT_TIME)}
              width={18}
              {...register("time", {
                required: { value: true, message: "Time is required" },
              })}
              error={errors.time?.message}
              onClick={() => setIsTimeDrawerOpen(true)}
              readOnly
              value={selectedTime || ""}
              disabled={!selectedDate}
            />
          </div>
        </div>

        <Divider width={"38"} mt={8} mb={8} />

        <div className="mt-4">
          <div className="flex flex-row justify-around mt-8 gap-4">
            <Button
              textColor="text-mainWhite"
              backgroundColor="bg-functionalRed"
              width={18}
              onClick={handleCancel}
              height={3}
            >
              {t(COMMON_TRANSLATE_KEYS.CANCEL)}
            </Button>
            <Button
              textColor="text-black-500"
              backgroundColor="bg-functionalGreen"
              width={18}
              type="submit"
              height={3}
              isDisabled={!isValid || selectedField?.value?.isUnavailable}
            >
              {t(COMMON_TRANSLATE_KEYS.BOOK)}
            </Button>
          </div>
        </div>
      </form>

      <MyDrawer isOpen={isDateDrawerOpen}>
        <div className="bg-additionalBlack">
          <div className="flex justify-between my-4">
            <p>{t(COMMON_TRANSLATE_KEYS.SELECT_DATE)}</p>
            <button onClick={closeDrawer} className="text-white">
              X
            </button>
          </div>
          {/* We unmount the calendar in order to make use of the openToDate prop */}
          {isDateDrawerOpen && (
            <DateGridCalendar
              onSelectDate={handleDateSelect}
              selectedDate={selectedDate}
              filteredDates={filteredDates}
              onMonthDateChange={(month: number, year: number) =>
                fetchReservationSlots(undefined, month, year)
              }
              openToDate={selectedDate || new Date()}
            />
          )}
        </div>
      </MyDrawer>

      <MyDrawer isOpen={isTimeDrawerOpen}>
        <div className="bg-additionalBlack">
          <div className="flex justify-between my-4">
            <p>{t(COMMON_TRANSLATE_KEYS.SELECT_TIME)}</p>
            <button onClick={closeDrawer} className="text-white">
              X
            </button>
          </div>
          <TimeGridCalendar
            timeSlots={timeSlots}
            onSelectTime={handleTimeSelect}
          />
        </div>
      </MyDrawer>
    </div>
  );
};

export default BookField;
