import React from "react";
import { PLAYER_ROLES } from "../constants/players";

type PlayerImageProps = {
  name: string;
  role: string;
  imageUrl?: string | null;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  hidden?: boolean;
};

const PlayerImage: React.FC<PlayerImageProps> = ({ name, role, imageUrl, onClick, hidden }) => {
  const crownSrc =
    role === PLAYER_ROLES.CAPTAIN
      ? "/icons/golden-crown.svg"
      : role === PLAYER_ROLES.VICE_CAPTAIN
      ? "/icons/silver-crown.svg"
      : null;

  return (
    <div className={`relative flex flex-col items-center cursor-pointer transition-all duration-200 ease-out transform
          ${hidden ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}`} onClick={onClick}>
      <div className="relative mt-4">
        <img
          src={imageUrl || "ellipse.png"}
          alt="Player"
          width={64}
          height={64}
          className="rounded-full w-[64px] h-[64px] object-cover"
        />
        {crownSrc && (
          <img
            src={crownSrc}
            alt="Crown"
            className="absolute -top-[18px] left-[14px] w-8 h-8"
          />
        )}
      </div>
      <p className="mt-2">{name}</p>
    </div>
  );
};

export default PlayerImage;
