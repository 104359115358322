import React, { ReactNode } from "react";
import AuthProvider from "./AuthContext";
import ComplexesProvider from "./ComplexesContext";
import AvailableReservationsProvider from "./AvailableReservationSlotsContext";
import FirebaseMessagingProvider from "./FirebaseMessagingContext";
import NotificationsProvider from "./NotificationsContext";
import UnreadNotificationsProvider from "./UnreadNotificationsContext";
import CurrenciesProvider from "./CurrenciesContext";
import FlooringTypesProvider from "./FlooringTypesContext";
import PricePerHourProvider from "./PricePerHourContext";
import { LengthProvider } from "./LengthContext";
import { WidthProvider } from "./WidthContext";

interface TProps {
  children: ReactNode;
}

const ContextsWrapper = ({ children }: TProps) => (
  <FirebaseMessagingProvider>
    <AuthProvider>
      <UnreadNotificationsProvider>
        <NotificationsProvider>
          <ComplexesProvider>
            <AvailableReservationsProvider>
              <WidthProvider>
                <LengthProvider>
                  <PricePerHourProvider>
                    <CurrenciesProvider>
                      <FlooringTypesProvider>{children}</FlooringTypesProvider>
                    </CurrenciesProvider>
                  </PricePerHourProvider>
                </LengthProvider>
              </WidthProvider>
            </AvailableReservationsProvider>
          </ComplexesProvider>
        </NotificationsProvider>
      </UnreadNotificationsProvider>
    </AuthProvider>
  </FirebaseMessagingProvider>
);

export default ContextsWrapper;
