import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  ROUTE_HELP,
  ROUTE_MY_FIELDS,
  ROUTE_RESERVATION,
  ROUTE_SETTINGS,
} from "../router/constants";
import { useTranslation } from "react-i18next";
import { useLogout } from "../hooks/useLogout";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const { logout } = useLogout();

  const navigate = useNavigate();
  const location = useLocation();

  const getTabFromPath = (path: string) => {
    if (path.includes("/settings")) return "Settings";
    if (path.includes("/my-fields")) return "MyFields";
    if (path.includes("/reservation")) return "Reservation";
    if (path.includes("/help")) return "Help";
    if (path.includes("/logout")) return "Log Out";
    return "MyFields";
  };

  const [selectedTab, setSelectedTab] = useState(
    getTabFromPath(location.pathname)
  );

  useEffect(() => {
    setSelectedTab(getTabFromPath(location.pathname));
  }, [location.pathname]);

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
    if (tabName === "Settings") {
      navigate(ROUTE_SETTINGS);
    }
    if (tabName === "MyFields") {
      navigate(ROUTE_MY_FIELDS);
    }
    if (tabName === "Reservation") {
      navigate(ROUTE_RESERVATION);
    }
    if (tabName === "Help") {
      navigate(ROUTE_HELP);
    }
    if (tabName === "Log Out") {
      logout();
    }
  };

  return (
    <div className="hidden md:flex flex-col justify-between flex-shrink-0 h-screen lg:w-[209px] bg-mainBlack text-mainWhite border-r border-dividerGray sticky">
      <div className="flex flex-col justify-center items-center mt-[36px] ml-[31px] mr-[24px]">
        <div className="flex flex-row items-center mb-8">
          <img src="/icons/logo.svg" alt="Logo" />
          <div className="text-xl hover:cursor-pointer">bolarena</div>
        </div>
        <div>
          <div
            className="flex flex-row items-center mb-6"
            onClick={() => handleTabClick("Reservation")}
          >
            <img
              src={
                selectedTab === "Reservation"
                  ? "/icons/calendar-minus-highlighted.svg"
                  : "/icons/calendar-minus.svg"
              }
              alt="Logo"
            />
            <button
              className={`ml-2 ${
                selectedTab === "Reservation"
                  ? "text-mainWhite"
                  : "text-dividerGray"
              } font-inter ${
                selectedTab === "Reservation" ? "font-semibold" : "font-normal"
              } text-base leading-5`}
            >
              {t(COMMON_TRANSLATE_KEYS.RESERVATION)}
            </button>
          </div>
          <div
            className="flex flex-row items-center mb-6"
            onClick={() => handleTabClick("MyFields")}
          >
            <img
              src={
                selectedTab === "MyFields"
                  ? "/icons/basketball-highlighted.svg"
                  : "/icons/basketball.svg"
              }
              alt="Logo"
            />
            <button
              className={`ml-2 ${
                selectedTab === "MyFields"
                  ? "text-mainWhite"
                  : "text-dividerGray"
              } font-inter ${
                selectedTab === "MyFields" ? "font-semibold" : "font-normal"
              } text-base leading-5`}
            >
              {t(COMMON_TRANSLATE_KEYS.MY_FIELDS)}
            </button>
          </div>
          <div
            className="flex flex-row items-center mb-6"
            onClick={() => handleTabClick("Settings")}
          >
            <img
              src={
                selectedTab === "Settings"
                  ? "/icons/settings-highlighted.svg"
                  : "/icons/settings.svg"
              }
              alt="Logo"
            />
            <button
              className={`ml-2 ${
                selectedTab === "Settings"
                  ? "text-mainWhite"
                  : "text-dividerGray"
              } font-inter ${
                selectedTab === "Settings" ? "font-semibold" : "font-normal"
              } text-base leading-5`}
            >
              {t(COMMON_TRANSLATE_KEYS.SETTINGS)}
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center mt-[36px] mr-[24px]">
        <div>
          <div
            className="flex flex-row items-center mb-6"
            onClick={() => handleTabClick("Help")}
          >
            <img
              src={
                selectedTab === "Help"
                  ? "/icons/help-highlighted.svg"
                  : "/icons/help.svg"
              }
              alt="Logo"
            />
            <button
              className={`ml-2 ${
                selectedTab === "Help" ? "text-mainWhite" : "text-dividerGray"
              } font-inter ${
                selectedTab === "Help" ? "font-semibold" : "font-normal"
              } text-base leading-5`}
            >
              {t(COMMON_TRANSLATE_KEYS.HELP)}
            </button>
          </div>

          <div
            className="flex flex-row items-center mb-6"
            onClick={() => handleTabClick("Log Out")}
          >
            <img
              src={
                selectedTab === "Log Out"
                  ? "/icons/logout-highlighted.svg"
                  : "/icons/logout.svg"
              }
              alt="Logo"
            />
            <button
              className={`ml-2 ${
                selectedTab === "Log Out"
                  ? "text-mainWhite"
                  : "text-dividerGray"
              } font-inter ${
                selectedTab === "Log Out" ? "font-semibold" : "font-normal"
              } text-base leading-5`}
            >
              {t(COMMON_TRANSLATE_KEYS.LOG_OUT)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
