import { createContext, ReactNode, useCallback, useMemo, useState } from "react";

import { fetchUnreadNotifications } from "../api/notifications";

type TProps = {
  children: ReactNode;
};

type UnreadNotificationsContextValue = {
  unreadNotificationsCount: number;
  getUnreadNotificationsCount: () => Promise<void>;
  setUnreadNotificationsCount: (count: number) => void;
  clearUnreadNotificationsCount: () => void;
  resetUnreadNotificationsCount: () => void;
  error: any;
  fetched: boolean;
};

export const UnreadNotificationsContext = createContext<UnreadNotificationsContextValue>({
  unreadNotificationsCount: 0,
  getUnreadNotificationsCount: async () => undefined,
  setUnreadNotificationsCount: () => undefined,
  clearUnreadNotificationsCount: () => undefined,
  resetUnreadNotificationsCount: () => undefined,
  error: null,
  fetched: false,
});

const initialNotificationsCount = 0;

const UnreadNotificationsProvider = ({ children }: TProps) => {
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(initialNotificationsCount);
  const [error, setError] = useState<any>(null);
  const [fetched, setFetched] = useState(false);

  const updateArray = useCallback(async (newNotificationsCount: number) => {
    setUnreadNotificationsCount((prev) => prev + newNotificationsCount);
  }, []);

  const getUnreadNotificationsCount = useCallback(async () => {
    const { data, error } = await fetchUnreadNotifications();

    setFetched(true);

    if (error) {
      setError(error.error);
      setUnreadNotificationsCount(initialNotificationsCount);
      return;
    }

    if (data) {
      setUnreadNotificationsCount(data);
    }
  }, []);

  const clear = useCallback(() => {
    setUnreadNotificationsCount(0);
  }, []);

  const reset = useCallback(() => {
    setUnreadNotificationsCount(0);
    setError(null);
    setFetched(false);
  }, []);

  const value = useMemo(
    () => ({
      unreadNotificationsCount,
      error,
      fetched,
      setUnreadNotificationsCount: updateArray,
      getUnreadNotificationsCount,
      clearUnreadNotificationsCount: clear,
      resetUnreadNotificationsCount: reset,
    }),
    [
      updateArray,
      getUnreadNotificationsCount,
      clear,
      reset,
      unreadNotificationsCount,
      error,
      fetched,
    ]
  );

  return <UnreadNotificationsContext.Provider value={value}>{children}</UnreadNotificationsContext.Provider>;
};

export default UnreadNotificationsProvider;
