export const PASSWORDS_NOT_MATCH = 'passwords-dont-match';
export const PASSWORD_IS_SHORT = 'password-is-short';
export const EMAIL_IS_INVALID = 'email-is-invalid';
export const PHONE_IS_INVALID = 'phone-is-invalid';
export const NAME_IS_SHORT = 'name-is-short';
export const NAME_IS_REQUIRED = 'name-is-required';
export const PHONE_IS_REQUIRED = 'phone-is-required';
export const EMAIL_IS_REQUIRED = 'email-is-required';
export const PASSWORD_IS_REQUIRED = 'password-is-required';
export const REPEAT_PASSWORD_IS_REQUIRED = 'repeat-password-is-required';
export const RESERVATION_LIMIT_MAX_MIN = 'reservation-limit';
export const RESERVATION_LIMIT_IS_REQUIRED = 'reservation-limit-is-required';
export const RESERVATION_LIMIT_IS_INVALID = 'reservation-limit-is-invalid';

export const FIELD_NAME_IS_REQUIRED = 'field-name-is-required';
export const FIELD_NAME_IS_SHORT = 'field-name-is-short';
export const COMPLEX_IS_REQUIRED = 'complex-is-required';
export const CURRENCY_IS_REQUIRED = 'currency-is-required';
export const SIZE_IS_REQUIRED = 'size-is-required';
export const FLOOR_IS_REQUIRED = 'floor-is-required';
export const PRICE_IS_REQUIRED = 'price-is-required';
export const PRICE_MUST_BE_NUMBER = 'price-must-be-number';
export const LENGTH_IS_REQUIRED = 'length-is-required';
export const LENGTH_MUST_BE_NUMBER = 'length-must-be-number';
export const WIDTH_IS_REQUIRED = 'width-is-required';
export const WIDTH_MUST_BE_NUMBER = 'width-must-be-number';
