import * as yup from "yup";
import * as ERRORS from "../../constants/errors";

export const registerSchema = yup.object().shape({
  name: yup.string().min(3, ERRORS.NAME_IS_SHORT).required(ERRORS.NAME_IS_REQUIRED),
  email: yup.string().email(ERRORS.EMAIL_IS_INVALID).required(ERRORS.EMAIL_IS_REQUIRED),
  phone: yup
    .string()
    .min(5, ERRORS.PHONE_IS_INVALID)
    .max(15, ERRORS.PHONE_IS_INVALID)
    .required(ERRORS.PHONE_IS_REQUIRED),
  password: yup.string().min(6, ERRORS.PASSWORD_IS_SHORT).required(ERRORS.PASSWORD_IS_REQUIRED),
  repeatPassword: yup.string().test({
    name: 'passwords-match',
    message: ERRORS.PASSWORDS_NOT_MATCH,
    test: (value, context) => context.parent.password === value,
  }).required(ERRORS.REPEAT_PASSWORD_IS_REQUIRED),
}).required();
