import React from "react";

import DarkenBackground from "../components/DarkenBackground";

const NotFound = () => (
    <div className="relative h-screen flex justify-center ">

    <DarkenBackground />

    <div className="z-20 flex w-full items-center">
      <h3 className="flex w-full text-center text-white justify-center text-3xl font-semibold font-montserrat">Page not found</h3>
    </div>
  </div>
);

export default NotFound;
