export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  }
}

export function getIsDocumentHidden() {
  const browserDocumentHiddenProp = getBrowserDocumentHiddenProp();

  if (browserDocumentHiddenProp) {
    return !document[browserDocumentHiddenProp];
  }

  return false;
}
