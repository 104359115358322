import { t } from "i18next";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import dayjs from "dayjs";

export const DATE_FORMATS = {
  WEEK_FIRST: 'ddd, DD MMMM',
  WEEK_FIRST_WITH_YEAR: 'ddd, DD MMMM, YYYY',
  HOURS_MINUES: 'HH:mm',
};

export const getDateForCalendar = (date: string) =>
  new Date(date.replace('Z', ''));

export const getRecurringDays = (days: number[]) => {
  const sortedDays = days.sort((a, b) => a - b);

  return sortedDays.map((day) => {
    switch (day) {
      case 0:
        return t(COMMON_TRANSLATE_KEYS.SUNDAY_SHORT);
      case 1:
        return t(COMMON_TRANSLATE_KEYS.MONDAY_SHORT);
      case 2:
        return t(COMMON_TRANSLATE_KEYS.TUESDAY_SHORT);
      case 3:
        return t(COMMON_TRANSLATE_KEYS.WEDNESDAY_SHORT);
      case 4:
        return t(COMMON_TRANSLATE_KEYS.THURSDAY_SHORT);
      case 5:
        return t(COMMON_TRANSLATE_KEYS.FRIDAY_SHORT);
      case 6:
        return t(COMMON_TRANSLATE_KEYS.SATURDAY_SHORT);
      default:
        return '';
    }
  });
}

export const getTimeAgoText = (date: Date, toLowerCase = false, dateToCompare = new Date()) => {
  const minuteDiff = dayjs(dateToCompare).diff(date, 'minute', true);
  const hourDiff = dayjs(dateToCompare).diff(date, 'hour', true);
  const dayDiff = dayjs(dateToCompare).diff(date, 'day', true);
  const weekDiff = dayjs(dateToCompare).diff(date, 'week', true);
  const monthDiff = dayjs(dateToCompare).diff(date, 'month', true);
  const yearDiff = dayjs(dateToCompare).diff(date, 'year', true);

  let translateKey = '';

  if (yearDiff > 1) {
    translateKey = t(COMMON_TRANSLATE_KEYS.TIME_AGO_YEARS, { count: Math.floor(yearDiff) });
  } else if (monthDiff > 1) {
    translateKey = t(COMMON_TRANSLATE_KEYS.TIME_AGO_MONTHS, { count: Math.floor(monthDiff) });
  } else if (weekDiff > 1) {
    translateKey = t(COMMON_TRANSLATE_KEYS.TIME_AGO_WEEKS, { count: Math.floor(weekDiff) });
  } else if (dayDiff > 1) {
    translateKey = t(COMMON_TRANSLATE_KEYS.TIME_AGO_DAYS, { count: Math.floor(dayDiff) });
  } else if (hourDiff > 1) {
    translateKey = t(COMMON_TRANSLATE_KEYS.TIME_AGO_HOURS, { count: Math.floor(hourDiff) });
  } else if (minuteDiff > 1) {
    translateKey = t(COMMON_TRANSLATE_KEYS.TIME_AGO_MINUTES, { count: Math.floor(minuteDiff) });
  } else {
    translateKey = t(COMMON_TRANSLATE_KEYS.TIME_AGO_SECONDS);
  }

  if (toLowerCase) {
    return translateKey.toLowerCase();
  }

  return translateKey;
}