import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import { ROUTE_LOGIN } from "./constants";

type TProps = {
  children: ReactNode;
};  

const ProtectedRoute = ({ children }: TProps) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    return <Navigate to={ROUTE_LOGIN} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;