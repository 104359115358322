import React, { useState } from "react";

import { AvailableReservationTimeSlot } from "../../../types/reservations";
import Button from "../../buttons/Button";
import { COMMON_TRANSLATE_KEYS } from "../../../constants/translate-keys";
import { useTranslation } from "react-i18next";

interface TimeGridCalendarProps {
  onSelectTime: (time: string) => void;
  timeSlots: AvailableReservationTimeSlot[];
}

const TimeGridCalendar: React.FC<TimeGridCalendarProps> = ({ onSelectTime, timeSlots }) => {
  const [selectedTimeKey, setSelectedTimeKey] = useState<string | null>(null);


  const { t } = useTranslation();
  const handleTimeSlotClick = (slotKey: string) => {
    setSelectedTimeKey(slotKey);
  };

  const handleSave = () => {
    if (!selectedTimeKey) {
      return;
    }

    onSelectTime(selectedTimeKey);
  }

  const getTimeSlotButtonClassName = (key: string, isAvailable: boolean) => {
    let className = 'w-full py-2 px-4 rounded-lg text-white';

    if (key === selectedTimeKey) {
      className += ' bg-mainBlue';
    } else if (isAvailable) {
      className += ' bg-additionalGray';
    } else {
      className += ' bg-backGroundGray';
    }

    return className;
  }

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-2 gap-2 py-4">
        {timeSlots.map(({ slot, isAvailable }) => {
          const key = `${slot.start}-${slot.end}`;
  
          return (
            <span key={key} className={`w-full ${!isAvailable ? "opacity-50" : ""}`}>
              <button
                className={getTimeSlotButtonClassName(key, isAvailable)}
                onClick={() => handleTimeSlotClick(key)}
                disabled={!isAvailable}
              >
                {key}
              </button>
            </span>
          )
        })}
      </div>

      <div className="text-center text-base text-mainWhite mt-3">
        {selectedTimeKey}
      </div>

      <div className="flex flex-col items-center">
        <Button
          isDisabled={!selectedTimeKey}
          onClick={() => handleSave()}
          backgroundColor="bg-mainGreen"
          textColor="text-black"
          className="!w-[90%] my-6 content-center rounded-xl text-sm font-medium"
          height={2.5}
        >
          {t(COMMON_TRANSLATE_KEYS.SAVE)}
        </Button>
      </div>
    </div>
  );
};

export default TimeGridCalendar;
