import axios, { AxiosError } from "axios";

import { getHeaders } from "./utils/getHeader";
import { GetCurrenciesResult } from "../types/currencies";

type GetCurrenciesResultWithError = {
  data?: GetCurrenciesResult;
  error?: any;
};

export const fetchCurrencies = async (): Promise<GetCurrenciesResultWithError> => {
  let result: GetCurrenciesResultWithError = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/currencies`,
      {
        headers: getHeaders(true),
      }
    );

    result.data = response.data as GetCurrenciesResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching currencies:", error);
    result.error = response?.data;
  }

  return result
}