import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";

const SwitchLanguageButton = () => {
  const { i18n } = useTranslation();
  const [isEnglish, setIsEnglish] = useState(i18n.language === "en");

  const handleLanguageChange = () => {
    const newLanguage = isEnglish ? "bg" : "en";
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("i18nextLng", newLanguage);
    setIsEnglish(!isEnglish);
  };

  return (
    <Switch
      checked={isEnglish}
      onChange={handleLanguageChange}
      className={`${
        isEnglish ? "bg-backGroundGray" : "bg-backGroundGray"
      } relative inline-flex h-[38px] w-[60px] items-center rounded-full transition-colors`}
    >
      <span
        className={`${
          isEnglish
            ? "translate-x-6 bg-functionalGreen"
            : "translate-x-1 bg-functionalGreen"
        } inline-block h-[28px] w-[28px] transform rounded-full transition-transform text-backGroundGray`}
      >
        {isEnglish ? "EN" : "BG"}
      </span>
    </Switch>
  );
};

export default SwitchLanguageButton;
