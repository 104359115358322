import axios, { AxiosError } from "axios";

import { DeleteOwnerComplexResult, GetOwnerComplexesResult } from "../types/complexes";
import { getHeaders } from "./utils/getHeader";
import { Complex } from "../types";

type GetOwnerComplexesResultWithError = {
  data?: GetOwnerComplexesResult;
  error?: any;
};

type GetOwnerComplexByIdResultWithError = {
  data?: Complex;
  error?: any;
};

type DeleteOwnerComplexResultWithError = {
  data?: DeleteOwnerComplexResult;
  error?: any;
};

export const fetchComplexes = async () => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes`;

  let result: GetOwnerComplexesResultWithError = {};

  try {
    const response = await axios.get(
      url,
      {
        headers: getHeaders(true),
      }
    );

    result.data = response.data as GetOwnerComplexesResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching complexes:", error);
    result.error = response?.data;
  }

  return result;
};

export const fetchComplexById = async (complexId: number) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${complexId}`;

  let result: GetOwnerComplexByIdResultWithError = {};

  try {
    const response = await axios.get(
      url,
      {
        headers: getHeaders(true),
      }
    );

    result.data = response.data as Complex;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching complex by id:", error);
    result.error = response?.data;
  }

  return result;
};

export const deleteComplex = async (id: number) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${id}`;

  let result: DeleteOwnerComplexResultWithError = {};

  try {
    const response = await axios.delete(
      url,
      {
        headers: getHeaders(true),
      }
    );

    result.data = response.data as DeleteOwnerComplexResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error deleting complex:", error);
    result.error = response?.data;
  }

  return result;
};
