import React from "react";

interface ButtonProps {
  width?: number;
  height?: number;
  backgroundColor?: string;
  textColor?: string;
  border?: string;
  borderColor?: string;
  children: React.ReactNode;
  isDisabled?: boolean;
  onClick?: () => void;
  type?: "submit" | "reset" | "button" | undefined;
  className?: string;
  IconBefore?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  IconAfter?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const Button: React.FC<ButtonProps> = ({
  width,
  height,
  backgroundColor = "bg-transparent",
  textColor = "text-mainBlue",
  border = "",
  borderColor = "",
  type,
  children,
  isDisabled = false,
  className,
  onClick,
  IconBefore,
  IconAfter,
}) => {
  const borderClass = border ? `${border} ${borderColor} border` : "";

  return (
    <button
      disabled={isDisabled}
      className={`${
        isDisabled ? "cursor-not-allowed opacity-50 " : ""
      } px-4 py-2 rounded-2xl ${backgroundColor} ${textColor} ${borderClass} ${className}`}
      style={{
        width: width ? `${width}rem`: '100%',
        height: height ? `${height}rem` : undefined,
        textAlign: "center",
      }}
      onClick={onClick}
      type={type}
    >
      <span className="flex flex-row justify-center gap-3">
        {IconBefore && (
          <IconBefore className="max-w-5 text-white" />
        )}
        {children}
        {IconAfter && (
          <IconAfter className="max-w-5 text-white" />
        )}
      </span>
    </button>
  );
};

export default Button;
