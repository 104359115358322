import { Popover } from "@headlessui/react";
import { useCallback, useMemo } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NotificationToast from "./NotificationToast";
import Button from "../buttons/Button";
import { useNotifications } from "../../hooks/notifications/useNotifications";
import { ROUTE_ARENA_FIELD_RESERVATION } from "../../router/constants";
import { getStringOrNull } from "../../utils/getHelpers";
import { useUnreadNotifications } from "../../hooks/notifications/useUnreadNotifications";
import { markAsReadNotifications } from "../../api/notifications";
import { COMMON_TRANSLATE_KEYS } from "../../constants/translate-keys";

type TProps = {
  containerClassName?: string;
}

const NotificationsContainer = ({ containerClassName }: TProps) => {
  const { notifications, getNotifications, loadMore, error, hasNext, loading } = useNotifications();
  const { unreadNotificationsCount, clearUnreadNotificationsCount } = useUnreadNotifications();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const popoverClassName = useMemo(() => {
    let className = 'relative';
    if (containerClassName) {
      className += ` ${containerClassName}`;
    }
    return className;
  }, [containerClassName]);

  const readNotifications = useCallback(async () => {
    if (unreadNotificationsCount > 0) {
      await markAsReadNotifications();
      await getNotifications();
      clearUnreadNotificationsCount();
    }
  }, [unreadNotificationsCount, clearUnreadNotificationsCount, getNotifications]);

  const renderNotifications = useCallback((close: () => void) => {
    if (notifications.length === 0) {
      return <p className="text-white text-center p-3">{t(COMMON_TRANSLATE_KEYS.NO_NOTIFICATIONS)}</p>;
    }

    return notifications.map((notification) => {
      const { title, body, status, reservation } = notification;

      let className = 'p-2 rounded-md border cursor-pointer';

      if (['PENDING', 'SENT'].includes(status)) {
        className += ' border-mainBlue';
      } else {
        className += ' border-dividerGray';
      }

      return (
        <NotificationToast
          key={notification.id}
          title={title}
          body={body}
          notificationData={notification}
          className={className}
          onClick={async () => {
            close();
            navigate(
              generatePath(ROUTE_ARENA_FIELD_RESERVATION, {
                id: getStringOrNull(reservation?.field.complexId),
                fieldId: getStringOrNull(reservation?.field.id),
              }),
            );
            await readNotifications();
          }}
        />
      );
    })
  }, [notifications, readNotifications, navigate, t]);

  return (
    <Popover className={popoverClassName}>
      <Popover.Button className="flex w-8">
        <img
          src="/icons/bell.svg"
          alt="Notification Bell"
          className="w-8 h-8 mr-4"
        />

        {(unreadNotificationsCount > 0 && Notification.permission === 'granted') && (
          <span className="rounded-full bg-cancel p-[0.6rem] text-white text-xxs w-4 h-4 flex items-center justify-center absolute top-0 right-0 mr-3">{unreadNotificationsCount > 10 ? '10+' : `${unreadNotificationsCount}`}</span>
        )}
      </Popover.Button>
      <Popover.Panel
        className={`absolute top-8 rounded-md -left-[100%] flex flex-col border-2 ${notifications.length > 0 ? 'border-additionalBlack' : 'border-dividerGray'} bg-mainBlack w-[17rem] z-50 overflow-auto max-h-[27rem]`}
        onMouseLeave={readNotifications}
      >
        {({ close }) => (
          <>
            {Notification.permission !== 'granted' && (
              <p className="text-white text-center p-3 border border-red-900">
                {t(COMMON_TRANSLATE_KEYS.NOTIFICATIONS_NOT_ALLOWED_TEXT)}
              </p>
            )}
          
            {error ? (
              <p className="text-white text-center p-3">{error}</p>
            ) : (
              renderNotifications(close)
            )}
            {hasNext && (
              <div className="flex flex-row justify-center p-4 bg-mainBlack">
                <Button
                  isDisabled={loading}
                  onClick={() => loadMore()}
                  width={15}
                  className="border border-additionalGray"
                >
                  {t(COMMON_TRANSLATE_KEYS.LOAD_MORE)}
                </Button>
              </div>
            )}
          </>
        )}
      </Popover.Panel>
    </Popover>
  )
};

export default NotificationsContainer;