import React, { ReactNode, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import Button from "../buttons/Button";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Complex } from "../../types";
import { useTranslation } from "react-i18next";
import { COMMON_TRANSLATE_KEYS } from "../../constants/translate-keys";
import ImageCarousel from "../carousel/ImageCarousel";
import { getFileNameFromUrl } from "../../utils/files";

interface ComplexCardProps {
  title: string;
  location: string;
  children: ReactNode;
  width?: "auto" | "sm" | "md" | "lg" | "xl";
  complex?: Complex;
}

const ComplexCard: React.FC<ComplexCardProps> = ({
  title,
  location,
  children,
  width = "",
  complex,
}) => {
  const getWidthClass = (width: string) => {
    switch (width) {
      case "sm":
        return "";
      case "md":
        return "";
      case "lg":
        return "w-[620px]";
      case "xl":
        return "w-full";
      default:
        return "";
    }
  };

  const navigate = useNavigate();

  const { t } = useTranslation();

  const carouselImages = useMemo(() => {
    return complex?.imageUrls.map((src) => ({
      id: getFileNameFromUrl(src, 'complex'),
      src: src,
    })) || [];
  }, [complex]);

  const renderButton = width !== "xl" && (
    <Button
      backgroundColor="bg-secondaryBlack"
      width={12}
      textColor="text-mainWhite"
      onClick={() =>
        navigate(`/arena/${complex?.id}/fields?name=${complex?.name}`)
      }
    >
      <div className="flex flex-row justify-between">
      {t(COMMON_TRANSLATE_KEYS.ALL_FIELDS)} <ChevronRightIcon height={24} width={24} />
      </div>
    </Button>
  );

  return (
    <div
      className={`rounded-lg bg-secondaryBlack shadow-lg ${getWidthClass(
        width
      )}`}
    >
      {width === "xl" ? (
        <>
          <div
            className={`relative rounded-lg bg-secondaryBlack shadow-lg ${getWidthClass(
              width
            )}`}
          >
            {carouselImages.length > 0 && (
              <div className="relative flex flex-col">
                <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-secondaryBlack via-semi-transparent to-transparent z-10" />
                <ImageCarousel
                  images={carouselImages}
                  containerClassName="relative"
                  itemClassName="w-[450px] h-[300px] p-5"
                  itemsShown={{
                    desktop: 3,
                  }}
                  showDots={false}
                  readonly
                />
              </div>
            )}
            <div className="flex flex-col h-full" style={{ paddingTop: carouselImages.length === 0 ? '300px' : 0 }}>
              <h2 className="text-6xl text-mainWhite font-bold mb-2 pl-4">
                {title}
              </h2>
              <div className="p-4 text-mainWhite flex flex-row justify-between ">
                <span className="text-base">
                  <p>{location}</p>
                  <p>{complex?.district}</p>
                </span>
                {children}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {carouselImages.length > 0 && (
            <div className="relative overflow-hidden rounded-t-lg">
              <ImageCarousel
                images={carouselImages}
                containerClassName="absolute"
                itemClassName="h-[200px]"
                itemsShown={{
                  desktop: 1,
                }}
                showDots={false}
                readonly
              />
              <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-secondaryBlack via-semi-transparent to-transparent z-10" />
            </div>
          )}
          <div className="p-4 text-mainWhite" style={{ paddingTop: carouselImages.length === 0 ? '200px' : 0 }}>
            <div className="flex flex-row justify-between">
              <div>
                <h2 className="text-xl font-bold mb-2">{title}</h2>
                <p className="text-sm mb-2">{location}</p>
              </div>
              <div>{renderButton}</div>
            </div>
            {children}
          </div>
        </>
      )}
    </div>
  );
};

export default ComplexCard;
