import axios from "axios";
import { PlayerSize } from "../types";
import { getToken } from "../utils/token";

export const getPlayerSizes = async (page = 1, limit = 50): Promise<PlayerSize[]> => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/fields/player-sizes?page=${page}&limit=${limit}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching player sizes:", error);
    }

    return [];
}