import React, { useCallback, useState } from "react";
import Dropzone from "../components/Dropzone";
import InputField from "../components/InputField";
import SwitchButton from "../components/SwitchButton";
import Divider from "../components/Divider";
import Button from "../components/buttons/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../utils/token";
import { toBase64 } from "../utils/files";
import DescriptionInput from "../components/DescriptionInput";
import { toast } from "react-toastify";
import { useComplexes } from "../hooks/useComplexes";
import { useTranslation } from "react-i18next";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import { getHeaders } from "../api/utils/getHeader";
import { TImage } from "../types";

const formatTime = (value: string) => {
  const trimmed = value.replace(/[^0-9]/g, "");
  if (trimmed.length === 0) return "";
  if (trimmed.length <= 2) {
    return `${trimmed.padStart(2, "0")}:00`;
  }
  if (trimmed.length === 3) {
    return `${trimmed.slice(0, 2)}:${trimmed.slice(2).padEnd(2, "0")}`;
  }
  if (trimmed.length === 4) {
    return `${trimmed.slice(0, 2)}:${trimmed.slice(2)}`;
  }
  return value;
};

const isTimeInRange = (time: string) => {
  const [hours] = time.split(":").map(Number);
  return hours >= 1 && hours <= 23;
};

const CreateComplex = () => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [phone, setPhone] = useState("");
  const [openingHours, setOpeningHours] = useState("");
  const [closingHours, setClosingHours] = useState("");
  const [description, setDescription] = useState("");
  const [parking, setParking] = useState(false);
  const [dressing, setDressing] = useState(false);
  const [showers, setShowers] = useState(false);
  const [coffee, setCoffee] = useState(false);
  const [providesBalls, setProvidesBalls] = useState(false);
  const [providesTShirts, setProvidesTShirts] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [images, setImages] = useState<TImage[]>([]);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [hoursError, setHoursError] = useState<string | null>(null);

  const navigate = useNavigate();

  const { getComplexes } = useComplexes();

  const addImages = useCallback((newImages: TImage[]) => {
    setImages((prev) => [...prev, ...newImages]);
  }, []);

  const removeImage = useCallback((image: TImage) => {
    setImages((prev) => prev.filter((i) => i.id !== image.id));
  }, []);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;

    if (regex.test(value)) {
      setPhone(value);
      setPhoneError(null);
    } else {
      setPhoneError(`${t(COMMON_TRANSLATE_KEYS.PHONE_ERROR)}`);
    }
  };

  const handleOpeningHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpeningHours(e.target.value);
  };

  const handleClosingHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClosingHours(e.target.value);
  };

  const handleOpeningHoursBlur = () => {
    const formattedTime = formatTime(openingHours);
    setOpeningHours(formattedTime);
    setHoursError(validateHours(formattedTime, closingHours));
  };

  const handleClosingHoursBlur = () => {
    const formattedTime = formatTime(closingHours);
    setClosingHours(formattedTime);
    setHoursError(validateHours(openingHours, formattedTime));
  };

  const validateHours = (opening: string, closing: string) => {
    let error: string | null = null;

    if (!/^\d{2}:\d{2}$/.test(opening)) {
      error = t(COMMON_TRANSLATE_KEYS.HOURS_ERROR);
    } else if (!/^\d{2}:\d{2}$/.test(closing)) {
      error = t(COMMON_TRANSLATE_KEYS.HOURS_ERROR);
    } else if (!isTimeInRange(opening)) {
      error = t(COMMON_TRANSLATE_KEYS.OPENING_HOURS_ERROR);
    } else if (!isTimeInRange(closing)) {
      error = t(COMMON_TRANSLATE_KEYS.CLOSING_HOURS_ERROR);
    } else if (opening >= closing) {
      error = t(COMMON_TRANSLATE_KEYS.OPEN_CLOSE_HOURS_ERROR);
    }
    return error;
  };

  const handleSubmit = async () => {
    if (phoneError) {
      toast.error(`${t(COMMON_TRANSLATE_KEYS.PHONE_ERROR)}`);
      return;
    }
    if (hoursError) {
      toast.error(`${t(COMMON_TRANSLATE_KEYS.HOURS_ERROR)}`);
      return;
    }

    const base64Images = await Promise.all(images.map((img) => toBase64(img.file)))
      .then(res => res.filter(Boolean));

    const complexData = {
      name,
      country,
      city,
      address,
      district,
      phone,
      openingHours,
      closingHours,
      description,
      parking,
      dressing,
      showers,
      coffee,
      providesBalls,
      providesTShirts,
      latitude,
      longitude,
      images: base64Images,
    };

    try {
      // Send a POST request to the backend to create a complex
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes`,
        complexData,
        {
          headers: getHeaders(true),
        }
      );
      console.log("Complex created successfully", response.data);
      toast.success("Complex created successfully!");
      resetForm();

      await getComplexes();

      navigate("/my-fields");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorResponse = error.response?.data;
        let errorMessage = "An unexpected error occurred";

        if (errorResponse?.details) {
          const { msg, path } = errorResponse.details;

          if (path === "openingHours" || path === "closingHours") {
            errorMessage = `${msg}. Hours must be in HH:MM format`;
          } else {
            errorMessage = msg || error.message;
          }
        } else {
          errorMessage = error.message;
        }

        toast.error(`Error creating complex: ${errorMessage}`);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  const resetForm = () => {
    setName("");
    setCountry("");
    setCity("");
    setAddress("");
    setDistrict("");
    setPhone("");
    setOpeningHours("");
    setClosingHours("");
    setDescription("");
    setParking(false);
    setDressing(false);
    setShowers(false);
    setCoffee(false);
    setProvidesBalls(false);
    setProvidesTShirts(false);
    setLatitude(0);
    setLongitude(0);
    setImages([]);
    setHoursError(null);
    setPhoneError(null);
  };

  const handleCancel = () => {
    resetForm();
    navigate("/my-fields");
  };

  return (
    <>
      <div className="mx-auto mb-4">
        <div className="text-mainWhite">
          <p>{t(COMMON_TRANSLATE_KEYS.PHOTOS_OF_THE_COMPLEX)}</p>
          <Dropzone addImages={addImages} removeImage={removeImage} images={images} />
        </div>
        <div className="mt-4">
          <p className="text-mainWhite">
            {t(COMMON_TRANSLATE_KEYS.NAME_OF_THE_COMPLEX)}
            <span className="text-functionalRed">*</span>
          </p>
          <InputField
            placeholder={t(COMMON_TRANSLATE_KEYS.NAME)}
            value={name}
            onChange={(e) => setName(e.target.value)}
            width={37}
          />
        </div>
        <div className="flex flex-row gap-x-4">
          <div className="mt-4">
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.COUNTRY)}
              <span className="text-functionalRed">*</span>
            </p>
            <InputField
              placeholder={t(COMMON_TRANSLATE_KEYS.COUNTRY)}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              width={18}
            />
          </div>
          <div className="mt-4">
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.REGION)}
              <span className="text-functionalRed">*</span>
            </p>
            <InputField
              placeholder={t(COMMON_TRANSLATE_KEYS.REGION)}
              value={city}
              onChange={(e) => setCity(e.target.value)}
              width={18}
            />
          </div>
        </div>

        <div className="mt-4">
          <p className="text-mainWhite">
            {t(COMMON_TRANSLATE_KEYS.ADDRESS)}
            <span className="text-functionalRed">*</span>
          </p>
          <InputField
            placeholder={t(COMMON_TRANSLATE_KEYS.ADDRESS)}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            width={37}
          />
        </div>
        <div className="mt-4">
          <p className="text-mainWhite">{t(COMMON_TRANSLATE_KEYS.DISTRICT)}</p>
          <InputField
            placeholder={t(COMMON_TRANSLATE_KEYS.DISTRICT)}
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
            width={37}
          />
        </div>
        <div className="mt-4">
          <p className="text-mainWhite">
            {t(COMMON_TRANSLATE_KEYS.CONTACT_PHONE)}
            <span className="text-functionalRed">*</span>
          </p>

          <InputField
            placeholder={t(COMMON_TRANSLATE_KEYS.CONTACT_PHONE)}
            value={phone}
            onChange={handlePhoneChange}
            width={37}
          />
          {phoneError && (
            <div className="text-functionalRed mb-2">{phoneError}</div>
          )}
        </div>
        <div className="mt-4 flex flex-row justify-between">
          <div>
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.OPENING_HOURS)}
              <span className="text-functionalRed">*</span>
            </p>
            <InputField
              placeholder={t(COMMON_TRANSLATE_KEYS.OPENING_HOURS)}
              value={openingHours}
              onChange={handleOpeningHoursChange}
              onBlur={handleOpeningHoursBlur}
              width={18}
            />
          </div>
          <div>
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.CLOSING_HOURS)}
              <span className="text-functionalRed">*</span>
            </p>
            <InputField
              placeholder={t(COMMON_TRANSLATE_KEYS.CLOSING_HOURS)}
              value={closingHours}
              onChange={handleClosingHoursChange}
              onBlur={handleClosingHoursBlur}
              width={18}
            />
          </div>
        </div>
        {hoursError && (
          <div className="text-functionalRed max-w-xl w-full break-words">
            {hoursError}
          </div>
        )}

        <div className="mt-4 mb-4">
          <p className="text-mainWhite">
            {t(COMMON_TRANSLATE_KEYS.ADDITIONAL_INFO)}
          </p>
          <DescriptionInput
            placeholder={description}
            onChange={(e) => setDescription(e.target.value)}
            width={37}
            height={10}
          />
        </div>
        <div className="flex flex-row justify-around mb-4">
          <SwitchButton
            width={12}
            isSelected={parking}
            onClick={() => setParking(!parking)}
          >
            {t(COMMON_TRANSLATE_KEYS.PARKING)}
          </SwitchButton>
          <SwitchButton
            width={12}
            isSelected={dressing}
            onClick={() => setDressing(!dressing)}
          >
            {t(COMMON_TRANSLATE_KEYS.DRESSING_ROOM)}
          </SwitchButton>
          <SwitchButton
            width={12}
            isSelected={coffee}
            onClick={() => setCoffee(!coffee)}
          >
            {t(COMMON_TRANSLATE_KEYS.COFFEE_SHOP)}
          </SwitchButton>
        </div>
        <div className="flex flex-row justify-around mb-4">
          <SwitchButton
            width={12}
            isSelected={showers}
            onClick={() => setShowers(!showers)}
          >
            {t(COMMON_TRANSLATE_KEYS.SHOWERS)}
          </SwitchButton>
          <SwitchButton
            width={12}
            isSelected={providesBalls}
            onClick={() => setProvidesBalls(!providesBalls)}
          >
            {t(COMMON_TRANSLATE_KEYS.PROVIDE_BALLS)}
          </SwitchButton>
          <SwitchButton
            width={12}
            isSelected={providesTShirts}
            onClick={() => setProvidesTShirts(!providesTShirts)}
          >
            {t(COMMON_TRANSLATE_KEYS.PROVIDE_T_SHIRTS)}
          </SwitchButton>
        </div>
        <Divider mb={2} mt={2} width={36} />
        <div className="flex flex-row justify-around mt-4 mb-4">
          <Button
            textColor="text-mainWhite"
            backgroundColor="bg-functionalRed"
            width={18}
            onClick={() => handleCancel()}
          >
            {t(COMMON_TRANSLATE_KEYS.CANCEL)}
          </Button>
          <Button
            textColor="text-black-500"
            backgroundColor="bg-functionalGreen"
            width={18}
            onClick={handleSubmit}
          >
            {t(COMMON_TRANSLATE_KEYS.CREATE)}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateComplex;

// над try в post Use FormData to send both the JSON data and the image file
// const formData = new FormData();
// formData.append("data", JSON.stringify(complexData));
// if (image) {
//   formData.append("image", image, image.name);
// }
