export const COMMON_TRANSLATE_KEYS = {
  RESERVATION: "reservation",
  RESERVATIONS: "reservations",
  COMPLEX: "complex",
  COMPLEXES: "complexes",
  FIELDS: "fields",
  FIELD: "field",
  GENERAL: "general",
  MY_FIELDS: "my_fields",
  SETTINGS: "settings",
  HELP: "help",

  DATE: "date",
  YES: "yes",
  NO: "no",
  TIME: "time",
  SELECT_DATE: "select-date",
  SELECT_TIME: "select-time",

  COMPLEX_SELECT: "complex-select",
  COMPLEX_SELECT_PLACEHOLDER: "complex-select-placeholder",
  OPTIONAL: "optional",
  NO_COMPLEXES: "no-complexes",
  NO_FIELDS: "no-fields",
  FIELD_ADDED_SUCCESSFULLY: "field-add-successful",
  FIELD_UPDATED_SUCCESSFULLY: "field-update-successful",
  FIELD_ADD_ERROR: "field-add-error",
  FIELD_DELETE_ERROR: "field-delete-error",

  STATUS_SELECT_PLACEHOLDER: "status-select-placeholder",

  CHECK_IN: "check-in",
  CHECK_OUT: "check-out",
  FEATURED: "featured",
  LOG_IN: "login",
  LOG_OUT: "log-out",

  ACCEPT: "accept",
  CANCEL: "cancel",
  DECLINE: "decline",
  ADD: "add",
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  DELETE_THIS_FIELD: "delete-this-field",
  DELETE_SURE: "delete-sure",
  UNAVAILABLE_FIELD: "unavailable-field",

  PENDING: "pending",
  ACCEPTED: "accepted",
  DECLINED: "declined",
  CANCELED: "canceled",
  UNKNOWN: "unknown",
  LOADING: "loading",

  DECLINE_RESERVATION_MODAL_TEXT: "decline-reservation-modal-text",
  DECLINE_REASON: "decline-reason",
  ENTER_TEXT: "enter-text",
  SEE_MORE: "see-more",
  SEE_DETAILS: "see-details",
  SAVE: "save",
  BOOK: "book",
  CHANGE: "change",
  VIEW_FIELDS: "view-fields",
  VIEW_CALENDAR: "view-calendar",
  ADD_FIELD: "add-field",
  CREATE_COMPLEX: "create-complex",
  EDIT_COMPLEX: "edit-complex",
  EDIT_FIELD: "edit-field",
  EDIT_INFO: "edit-info",
  DELETE_COMPLEX: "delete-complex",
  BOOK_FIELD: "book-field",
  ALL_FIELDS: "all-fields",

  FIELD_PAYMENT: "field-payment",
  GAME_TYPE: "game-type",
  RECURRING_DAYS: "recurring-days",
  END_RECURRING_DAY: "end-recurring-day",
  REPEAT: "repeat",
  WEEK_COUNT: "week-count",
  RECURRING: "recurring",

  TEAM: "team",
  OWNER: "owner",
  CAPTAIN: "captain",
  SECOND_CAPTAIN: "second-captain",

  // Sometimes we need to render only the private/public word. We use i18n context for bulgarian translations
  PRIVATE_GAME_CONTEXT: "private_game",
  PUBLIC_GAME_CONTEXT: "public_game",

  PRIVATE_GAME: "private-game",
  PUBLIC_GAME: "private-game",

  MONDAY_SHORT: "monday_short",
  TUESDAY_SHORT: "tuesday_short",
  WEDNESDAY_SHORT: "wednesday_short",
  THURSDAY_SHORT: "thursday_short",
  FRIDAY_SHORT: "friday_short",
  SATURDAY_SHORT: "saturday_short",
  SUNDAY_SHORT: "sunday_short",

  PLAYERS: "players",
  NICKNAME: "nickname",
  COUNTRY: "country",
  REGION: "region",
  DISTRICT: "district",
  ADDRESS: "address",
  CITY: "city",
  PHONE: "phone",
  PHONE_ERROR: "phone-error",
  EMAIL: "email",
  POSITION: "position",

  NAME: "name",
  PASSWORD: "password",
  PHONE_NUMBER: "phone-number",
  FIRST_NAME: "first-name",
  LAST_NAME: "last-name",
  DELETE_COMPLEX_CONFIRMATION_TEXT: "delete-complex-confirmation-text",
  CANCEL_DEADLINE_TEXT: "cancel-deadline-text",
  CANCEL_DEADLINE_UPDATE: "cancel-deadline-update",
  HOURS: "hours",
  NAME_OF_THE_PERSON: "name-of-the-person",
  PHONE_OF_THE_PERSON: "phone-of-the-person",

  DRAG_AND_DROP: "drag-and-drop",
  DROP_HERE: "drop-here",
  UPLOAD_FAILED: "upload-failed",
  PHOTOS_OF_THE_FIELD: "photos-of-the-field",
  PHOTOS_OF_THE_COMPLEX: "photos-of-the-complex",
  CHOOSE_A_COMPLEX: "choose-a-complex",
  CHOOSE_A_FIELD: "choose-a-field",
  NAME_OF_THE_FIELD: "name-of-the-field",
  NAME_OF_THE_COMPLEX: "name-of-the-complex",
  PRICE_PER_HOUR: "price-per-hour",
  CURRENCY: "currency",
  FIELD_LENGTH: "field-length",
  FIELD_WIDTH: "field-width",
  SIZE: "size",
  TYPE_OF_FLOORING: "type-of-flooring",
  IS_IT_COVERED: "is-it-covered",
  DOES_IT_HAVE_LIGHTING: "does-it-have-lighting",
  HEATING: "heating",
  TRIBUNES: "tribunes",
  PARKING: "parking",
  DRESSING_ROOM: "dressing-room",
  COFFEE_SHOP: "coffee-shop",
  SHOWERS: "showers",
  PROVIDE_BALLS: "provide-balls",
  PROVIDE_T_SHIRTS: "provide-t-shirts",
  CONTACT_PHONE: "contact-phone",
  CONTACT_OWNER: "contact-owner",
  OPENING_HOURS: "opening-hours",
  CLOSING_HOURS: "closing-hours",
  HOURS_ERROR: "hours-error",
  OPENING_HOURS_ERROR: "opening-hours-error",
  CLOSING_HOURS_ERROR: "closing-hour-error",
  OPEN_CLOSE_HOURS_ERROR: "open-close-hours-error",
  ADDITIONAL_INFO: "additional-info",
  COMPLEX_INFO: "complex-info",
  FIELD_INFO: "field-info",
  INFO: "info",
  PROFILE: "profile",
  DESCRIPTION: "description",
  M: "m",
  RESERVE_FOR_ANOTHER_PERSON: "reserve-for-another-person",

  UPDATED_PASSWORD: "updated_she",
  NO_PASSWORD_SET: "no-password-set",
  INCORRECT_CREDS: "incorrect-creds",

  TIME_AGO_YEARS: "time-ago_years",
  TIME_AGO_MONTHS: "time-ago_months",
  TIME_AGO_WEEKS: "time-ago_weeks",
  TIME_AGO_DAYS: "time-ago_days",
  TIME_AGO_HOURS: "time-ago_hours",
  TIME_AGO_MINUTES: "time-ago_minutes",
  TIME_AGO_SECONDS: "time-ago_seconds",

  WELCOME: "welcome",
  TO_BOLARENA: "to-bolarena",
  FORGOT_PASSWORD: "forgot-password",
  LOGIN: "login",
  NO_ACCOUNT_CURRENTLY: "no-account-currently",
  SIGN_UP_NOW: "sign-up-now",
  OR_LOGIN_WITH: "or-login-with",

  ACCOUNT: "account",
  REPEAT_PASSWORD: "repeat-password",
  REGISTER_BUTTON: "register-button",
  ALREADY_HAVE_ACC: "already-have-acc",

  LOAD_MORE: "load-more",
  NO_NOTIFICATIONS: "no-notifications",
  OWNER_NOT_FOUND: "owner-not-found",
  EMAIL_SENDING_ERROR: "email-sending-error",

  ENTER_CODE: "enter-code",
  RESET_PASS_TEXT: "reset-pass-text",
  NO_EMAIL: "no-email",
  SEND_AGAIN: "send-again",
  NEXT: "next",
  EXPIRED_CODE: "expired-code",
  RESET_PASSWORD: "reset-password",
  ENTER_NEW_PASS_TEXT: "enter-new-pass-text",
  RESET: "reset",

  UNEXPECTED_ERROR: "unexpected-error",

  NOTIFICATIONS_NOT_ALLOWED_TEXT: "not-allowed-notifications-text",
  ALLOW_NOTIFICATIONS: "allow",
};
