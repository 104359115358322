import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";

import InputField from "../components/InputField";
import SwitchButton from "../components/SwitchButton";
import Divider from "../components/Divider";
import Button from "../components/buttons/Button";
import DropdownMenu from "../components/DropdownMenu";
import Dropzone, { DropzoneImperativeHandle } from "../components/Dropzone";
import {
  MenuItem,
  Complex,
  Currency,
  PlayerSize,
  FlooringType,
  PricePerHour,
  Length,
  Width,
  TImage,
} from "../types";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import { toBase64 } from "../utils/files";
import { getPlayerSizes } from "../api/playerSize";
import { getToken } from "../utils/token";
import CurrencyDropdown from "../components/dropdowns/CurrencyDropdown";
import { getHeaders } from "../api/utils/getHeader";
import FlooringTypesDropdown from "../components/dropdowns/FlooringTypesDropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import { addFieldSchema } from "../validation/schemas/field";
import PricePerHourDropdown from "../components/dropdowns/PricePerHourDropdown";
import LengthDropdown from "../components/dropdowns/LengthDropdown";
import WidthDropdown from "../components/dropdowns/WidthDropdown";

interface IFormInput {
  complexId: number;
  currencyId: number;
  sizeId: number;
  floorId: number;
  fieldName: string;
  priceId: number;
  lengthId: number;
  widthId: number;
}

// tuk da sa number ?? i v useForm sushto

const AddField = () => {
  const { t } = useTranslation();

  const { id: complexId } = useParams<{ id: string }>(); 

  const {
    register,
    handleSubmit: handleFormSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      fieldName: "",
      complexId: 0,
      currencyId: 0,
      sizeId: 0,
      floorId: 0,
      priceId: 0,
      lengthId: 0,
      widthId: 0,
    },
    resolver: yupResolver(addFieldSchema),
    mode: "all",
  });

  const [images, setImages] = useState<TImage[]>([]);
  const [complexes, setComplexes] = useState<any[]>([]);
  const [selectedComplex, setSelectedComplex] =
    useState<MenuItem<Complex> | null>(null);
  const [selectedCurrency, setSelectedCurrency] =
    useState<MenuItem<Currency> | null>(null);
  const [sizeOptions, setSizeOptions] = useState<Array<MenuItem<PlayerSize>>>(
    []
  );
  const [selectedSize, setSelectedSize] = useState<MenuItem<PlayerSize> | null>(
    null
  );
  const [selectedPrice, setSelectedPrice] =
    useState<MenuItem<PricePerHour> | null>(null);
  const [selectedLength, setSelectedLength] = useState<MenuItem<Length> | null>(
    null
  );
  const [selectedWidth, setSelectedWidth] = useState<MenuItem<Width> | null>(
    null
  );
  const [selectedFloor, setSelectedFloor] =
    useState<MenuItem<FlooringType> | null>(null);
  const [isCovered, setIsCovered] = useState<boolean>(false);
  const [heating, setHeating] = useState<boolean>(false);
  const [lighting, setLighting] = useState<boolean>(false);
  const [tribunes, setTribunes] = useState<boolean>(false);
  const [hasParking, setHasParking] = useState<boolean>(false);
  const [hasDressingRoom, setHasDressingRoom] = useState<boolean>(false);
  const [showers, setShowers] = useState(false);
  const [hasCoffeeShop, setHasCoffeeShop] = useState<boolean>(false);
  const [provideBalls, setProvideBalls] = useState<boolean>(false);
  const [provideTShirts, setProvideTShirts] = useState<boolean>(false);
  const refDropzone = useRef<DropzoneImperativeHandle>();

  const navigate = useNavigate();

  const addImages = useCallback((newImages: TImage[]) => {
    setImages((prev) => [...prev, ...newImages]);
  }, []);

  const removeImage = useCallback((image: TImage) => {
    setImages((prev) => prev.filter((i) => i.id !== image.id));
  }, []);

  // Suggestion: Move axios request to api folder. Follow the example of loadPlayerSizes and loadFlooringTypes
  // Fetch available complexes
  const fetchComplexes = async (page = 1) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes?page=${page}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      setComplexes(response.data);
    } catch (error) {
      console.error("Error fetching complexes:", error);
    }
  };

  const loadPlayerSizes = async () => {
    const playerSizesData = await getPlayerSizes();

    const playerSizeOptions = playerSizesData.map((ps) => ({
      label: ps.label,
      value: ps,
      onClick: () => undefined,
    }));

    setSizeOptions(
      playerSizeOptions.map((ps, i) => ({
        ...ps,
        onClick: () => setSelectedSize(ps),
      }))
    );
  };

  const handleComplexClick = useCallback(
    (option: MenuItem<Complex>) => {
      setSelectedComplex(option);
      setValue("complexId", option.value.id, { shouldValidate: true });
      fetchComplexDetails(option.value.id);
    },
    [setValue]
  );

  const handleCurrencySelect = useCallback(
    (option: MenuItem<Currency>) => {
      setSelectedCurrency(option);
      setValue("currencyId", option.value.id, { shouldValidate: true });
    },
    [setValue]
  );

  const handleLengthSelect = useCallback(
    (option: MenuItem<Length>) => {
      setSelectedLength(option);
      setValue("lengthId", option.value.id, { shouldValidate: true });
    },
    [setValue]
  );

  const handleWidthSelect = useCallback(
    (option: MenuItem<Width>) => {
      setSelectedWidth(option);
      setValue("widthId", option.value.id, { shouldValidate: true });
    },
    [setValue]
  );


  const handlePriceSelect = useCallback(
    (option: MenuItem<PricePerHour>) => {
      setSelectedPrice(option);
      setValue("priceId", option.value.id, { shouldValidate: true });
    },
    [setValue]
  );

  const handleSizeSelect = useCallback(
    (option: MenuItem<PlayerSize>) => {
      setSelectedSize(option);
      setValue("sizeId", option.value.id, { shouldValidate: true });
    },
    [setValue]
  );

  const handleFlooringSelect = useCallback(
    (option: MenuItem<FlooringType>) => {
      setSelectedFloor(option);
      setValue("floorId", option.value.id, { shouldValidate: true });
    },
    [setValue]
  );

  const complexOptions = useMemo<Array<MenuItem<Complex>>>(
    () =>
      complexes.map((complex: Complex) => {
        const menuItem: MenuItem<Complex> = {
          label: complex.name,
          value: complex,
          onClick: () => {},
        };
        menuItem.onClick = () => handleComplexClick(menuItem);

        return menuItem;
      }),
    [complexes, handleComplexClick]
  );

  useEffect(() => {
    Promise.all([fetchComplexes(), loadPlayerSizes()]);
  }, []);

  useEffect(() => {
    if (complexId && complexOptions.length > 0) {
      const complexOption = complexOptions.find((option) => {
        return option.value.id.toString() === complexId;
      });

      if (complexOption) {
        setSelectedComplex(complexOption);
        fetchComplexDetails(complexOption.value.id);
        setValue("complexId", complexOption.value.id, { shouldValidate: true });
      }
    }
  }, [complexOptions, complexId, setValue]);

  const fetchComplexDetails = async (id?: number) => {
    if (!id) {
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${id}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );

      const complexData = response?.data;

      if (complexData) {
        setHasParking(complexData.parking || false);
        setHasDressingRoom(complexData.dressing || false);
        setHasCoffeeShop(complexData.coffee || false);
        setProvideBalls(complexData.providesBalls || false);
        setProvideTShirts(complexData.providesTShirts || false);
      }
    } catch (error) {
      console.error("Error fetching complex details:", error);
    }
  };

  const clearImages = useCallback(() => {
    if (refDropzone.current) {
      refDropzone.current.clearImages();
    }

    setImages([]);
  }, []);

  const handleSubmit = async (values: IFormInput) => {
    const images_base64: Array<string> = await Promise.all(
      images.map((im) => toBase64(im.file))
    )
      .then((res) => res.filter(x => x) as Array<string>);

    const formData = {
      name: values.fieldName,
      priceId: values.priceId,
      currencyId: values.currencyId,
      playerSizeId: values.sizeId,
      flooringTypeId: values.floorId,
      widthId: values.widthId,
      lengthId: values.lengthId,
      heating,
      lighting,
      tribunes,
      isCovered,
      images: images_base64,
    };

    if (selectedComplex == null) {
      toast.error(COMMON_TRANSLATE_KEYS.COMPLEX_SELECT, {
        theme: "dark",
        className: "text-center",
        bodyClassName: "custom-toast-body",
      });
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${values.complexId}/fields`,
        formData,
        {
          headers: getHeaders(true),
        }
      );

      toast.success(t(COMMON_TRANSLATE_KEYS.FIELD_ADDED_SUCCESSFULLY), {
        theme: "dark",
        className: "text-center",
        bodyClassName: "custom-toast-body",
      });

      resetForm();

      const navigateLink = complexId
        ? `/arena/${complexId}/info`
        : "/my-fields";

      navigate(navigateLink);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage =
          error.response?.data?.message ||
          t(COMMON_TRANSLATE_KEYS.FIELD_ADD_ERROR);
        toast.error(errorMessage, {
          theme: "dark",
          className: "text-center",
          bodyClassName: "custom-toast-body",
        });
      } else {
        toast.error(t(COMMON_TRANSLATE_KEYS.UNEXPECTED_ERROR), {
          theme: "dark",
          className: "text-center",
          bodyClassName: "custom-toast-body",
        });
      }
    }
  };

  const resetForm = () => {
    reset();
    setSelectedCurrency(null);
    setHeating(false);
    setLighting(false);
    setTribunes(false);
    setIsCovered(false);
    clearImages();
    setSelectedComplex(null);

    setHasParking(false);
    setHasDressingRoom(false);
    setHasCoffeeShop(false);
    setProvideBalls(false);
    setProvideTShirts(false);
  };

  const handleCancel = () => {
    resetForm();

    const navigateLink = complexId ? `/arena/${complexId}/info` : "/my-fields";

    navigate(navigateLink);
  };

  return (
    <div className="mx-auto mb-4">
      <div className="text-mainWhite">
        <p>{t(COMMON_TRANSLATE_KEYS.PHOTOS_OF_THE_FIELD)}</p>
        <Dropzone addImages={addImages} removeImage={removeImage} images={images} />
      </div>
      <div className="mt-4">
        <p className="text-mainWhite">
          {t(COMMON_TRANSLATE_KEYS.CHOOSE_A_COMPLEX)}{" "}
          <span className="text-functionalRed">*</span>
        </p>
        <div className="flex flex-row justify-between">
          <DropdownMenu<Complex>
            items={complexOptions}
            selectedOption={selectedComplex}
            setSelectedOption={handleComplexClick}
            error={t(errors.complexId?.message || "")}
            showIcon={false}
            width={37}
          />
        </div>
      </div>
      <div className="mt-4 mb-8">
        <p className="text-mainWhite">
          {t(COMMON_TRANSLATE_KEYS.NAME_OF_THE_FIELD)}
          <span className="text-functionalRed">*</span>
        </p>
        <div className="flex flex-row justify-between">
          <InputField
            placeholder={`${t(COMMON_TRANSLATE_KEYS.FIELD)}`}
            width={37}
            error={t(errors.fieldName?.message || "")}
            {...register("fieldName")}
          />
        </div>
        <div className="mt-4 flex flex-row justify-between">
          <div>
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.PRICE_PER_HOUR)}
              <span className="text-functionalRed">*</span>
            </p>
            <PricePerHourDropdown
              selectedPrice={selectedPrice}
              setSelectedPrice={handlePriceSelect}
              error={t(errors.priceId?.message || "")}
              showIcon={false}
              width={18}
              height={3}
            />
          </div>
          <div>
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.CURRENCY)}
              <span className="text-functionalRed">*</span>
            </p>
            <CurrencyDropdown
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={handleCurrencySelect}
              error={t(errors.currencyId?.message || "")}
              showIcon={false}
              width={18}
              height={3}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between mt-4">
          <div>
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.FIELD_LENGTH)}
              <span className="text-functionalRed">*</span>
            </p>
            <LengthDropdown
              selectedLength={selectedLength}
              setSelectedLength={handleLengthSelect}
              error={t(errors.lengthId?.message || "")}
              showIcon={false}
              width={18}
              height={3}
            />
          </div>
          <div>
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.FIELD_WIDTH)}
              <span className="text-functionalRed">*</span>
            </p>
            <WidthDropdown
              selectedWidth={selectedWidth}
              setSelectedWidth={handleWidthSelect}
              error={t(errors.widthId?.message || "")}
              showIcon={false}
              width={18}
              height={3}
            />
          </div>
        </div>
        <div className="mt-4 flex flex-row justify-between">
          <div>
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.SIZE)}{" "}
              <span className="text-functionalRed">*</span>
            </p>

            <DropdownMenu<PlayerSize>
              items={sizeOptions}
              selectedOption={selectedSize}
              setSelectedOption={handleSizeSelect}
              showIcon={false}
              width={18}
              error={t(errors.sizeId?.message || "")}
            />
          </div>
          <div>
            <p className="text-mainWhite">
              {t(COMMON_TRANSLATE_KEYS.TYPE_OF_FLOORING)}
              <span className="text-functionalRed">*</span>
            </p>

            <FlooringTypesDropdown
              selectedFlooring={selectedFloor}
              setSelectedFlooring={handleFlooringSelect}
              error={t(errors.floorId?.message || "")}
              showIcon={false}
              width={18}
            />
          </div>
        </div>
        <div className="flex flex-row justify-around gap-4 mt-4">
          <SwitchButton
            width={18}
            isSelected={isCovered}
            onClick={() => setIsCovered(!isCovered)}
          >
            {t(COMMON_TRANSLATE_KEYS.IS_IT_COVERED)}
          </SwitchButton>
          <SwitchButton
            width={18}
            isSelected={lighting}
            onClick={() => setLighting(!lighting)}
          >
            {t(COMMON_TRANSLATE_KEYS.DOES_IT_HAVE_LIGHTING)}
          </SwitchButton>
        </div>
        <div className="flex flex-row justify-around gap-4 mt-4">
          <SwitchButton
            width={18}
            isSelected={heating}
            onClick={() => setHeating(!heating)}
          >
            {t(COMMON_TRANSLATE_KEYS.HEATING)}
          </SwitchButton>
          <SwitchButton
            width={18}
            isSelected={tribunes}
            onClick={() => setTribunes(!tribunes)}
          >
            {t(COMMON_TRANSLATE_KEYS.TRIBUNES)}
          </SwitchButton>
        </div>
      </div>
      <Divider mb={0} mt={0} width={36} />

      <div className="flex flex-row justify-around mt-8">
        <SwitchButton
          width={12}
          isSelected={hasParking}
          isDisabled={true}
          onClick={() => setHasParking(!hasParking)}
        >
          {t(COMMON_TRANSLATE_KEYS.PARKING)}
        </SwitchButton>
        <SwitchButton
          width={12}
          isSelected={hasDressingRoom}
          isDisabled={true}
          onClick={() => setHasDressingRoom(!hasDressingRoom)}
        >
          {t(COMMON_TRANSLATE_KEYS.DRESSING_ROOM)}
        </SwitchButton>
        <SwitchButton
          width={12}
          isSelected={hasCoffeeShop}
          isDisabled={true}
          onClick={() => setHasCoffeeShop(!hasCoffeeShop)}
        >
          {t(COMMON_TRANSLATE_KEYS.COFFEE_SHOP)}
        </SwitchButton>
      </div>
      <div className="flex flex-row justify-around mt-4 mb-8">
        <SwitchButton
          width={12}
          isSelected={showers}
          isDisabled={true}
          onClick={() => setShowers(!showers)}
        >
          {t(COMMON_TRANSLATE_KEYS.SHOWERS)}
        </SwitchButton>
        <SwitchButton
          width={12}
          isSelected={provideBalls}
          isDisabled={true}
          onClick={() => setProvideBalls(!provideBalls)}
        >
          {t(COMMON_TRANSLATE_KEYS.PROVIDE_BALLS)}
        </SwitchButton>
        <SwitchButton
          width={12}
          isSelected={provideTShirts}
          isDisabled={true}
          onClick={() => setProvideTShirts(!provideTShirts)}
        >
          {t(COMMON_TRANSLATE_KEYS.PROVIDE_T_SHIRTS)}
        </SwitchButton>
      </div>
      <Divider mb={0} mt={0} width={36} />
      <div className="flex flex-row justify-around mt-8">
        <Button
          textColor="text-mainWhite"
          backgroundColor="bg-functionalRed"
          width={18}
          onClick={() => handleCancel()}
        >
          {t(COMMON_TRANSLATE_KEYS.CANCEL)}
        </Button>
        <Button
          textColor="text-black-500"
          backgroundColor="bg-functionalGreen"
          width={18}
          onClick={handleFormSubmit(handleSubmit)}
          // isDisabled={isSubmitDisabled}
        >
          {t(COMMON_TRANSLATE_KEYS.ADD)}
        </Button>
      </div>
    </div>
  );
};

export default AddField;
