import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Currency, MenuItem } from "../../types";
import DropdownMenu from "../DropdownMenu";
import { useCurrencies } from "../../hooks/useCurrencies";

type TProps = {
  selectedCurrency: MenuItem<Currency> | null;
  setSelectedCurrency: Function;
  showIcon?: boolean;
  width?: number;
  height?: number;
  placeholder?: string;
  transparent?: boolean;
  customLabel?: string; 
  isIconAdjacent?: boolean;
  isIconActive?: boolean;
  error?: string;
};

const CurrencyDropdown = ({
  selectedCurrency,
  setSelectedCurrency,
  ...rest
}: TProps) => {
  const { currencies, error, getCurrencies } = useCurrencies();

  const { i18n } = useTranslation();

  const currencyOptions = useMemo(() => {
    return currencies.map((c) => {
      const item = {
        label: i18n.language === "en" ? c.name : c.nameBg,
        value: c,
        onClick: () => undefined,
      };

      item.onClick = () => setSelectedCurrency(item);

      return item;
    })
  }, [currencies, setSelectedCurrency, i18n.language]);

  const selectedOption = useMemo(() => {
    if (!selectedCurrency) {
      return null;
    }

    const selected = { ...selectedCurrency };

    const label = i18n.language === "en"
      ? selectedCurrency.value.name
      : selectedCurrency.value.nameBg;

    selected.label = label;

    return selected;
  }, [selectedCurrency, i18n.language]);

  useEffect(() => {
    getCurrencies();
  }, [getCurrencies]);

  useEffect(() => {
    if (error) {
      toast(error.error, {
        toastId: 'fetch-currencies-error',
        theme: "dark",
        className: "text-center",
        bodyClassName: "custom-toast-body",
      });
    }
  }, [error]);

  return (
    <DropdownMenu<Currency>
      items={currencyOptions}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedCurrency}
      {...rest}
    />
  )
};

export default CurrencyDropdown;