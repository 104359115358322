import { CalendarContainerProps } from "react-datepicker/dist/calendar_container";
import { CalendarContainer as DatePickerContainer } from "react-datepicker";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import Button from "../../buttons/Button";
import { DATE_FORMATS } from "../../../utils/dates";
import { COMMON_TRANSLATE_KEYS } from "../../../constants/translate-keys";

import "./CalendarHeader.css";

import 'dayjs/locale/bg';
import { useMemo } from "react";

type TCalendarContainerProps = CalendarContainerProps & {
  date: Date | null;
  onSave: () => void;
  isButtonDisabled?: boolean;
};

const CalendarContainer = ({
  className,
  date,
  children,
  onSave,
  isButtonDisabled,
}: TCalendarContainerProps) => {
  const { t, i18n } = useTranslation();

  const formattedDate = useMemo(() =>
    date
      ? dayjs(date).locale(i18n.language).format(DATE_FORMATS.WEEK_FIRST_WITH_YEAR)
      : "",
    [date, i18n.language],
  );

  return (
    <div>
      <DatePickerContainer className={className}>
        <div className="relative">{children}</div>
        <div className="text-center text-sm text-mainWhite mt-3">
          {formattedDate}
        </div>
        <div className="flex flex-col items-center">
          <Button
            onClick={() => onSave()}
            backgroundColor="bg-mainGreen"
            textColor="text-black"
            className="!w-[90%] my-6 content-center rounded-xl text-sm font-medium"
            height={2.5}
            isDisabled={isButtonDisabled}
          >
            {t(COMMON_TRANSLATE_KEYS.SAVE)}
          </Button>
        </div>
      </DatePickerContainer>
    </div>
  );
};

export default CalendarContainer;
