import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { AppNotification } from "../types/notifications";
import { fetchNotifications } from "../api/notifications";

type TProps = {
  children: ReactNode;
};

type NotificationsContextValue = {
  notifications: AppNotification[];
  setNotifications: (complexes: AppNotification[]) => void;
  getNotifications: () => Promise<void>;
  clearNotifications: () => void;
  loadMore: () => void;
  error: any;
  fetched: boolean;
  hasNext: boolean;
  loading: boolean;
};

export const NotificationsContext = createContext<NotificationsContextValue>({
  notifications: [],
  setNotifications: () => undefined,
  getNotifications: async () => undefined,
  clearNotifications: () => undefined,
  loadMore: () => undefined,
  error: null,
  fetched: false,
  hasNext: false,
  loading: false,
});

const initialPageLimit = 10;

const NotificationsProvider = ({ children }: TProps) => {
  const [notifications, setNotifications] = useState<AppNotification[]>([]);
  const [error, setError] = useState<any>(null);
  const [fetched, setFetched] = useState(false);
  const [pageLimit, setPageLimit] = useState(initialPageLimit);
  const [hasNext, setHasNext] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateNotificationsArray = useCallback(async (newNotifications: AppNotification[]) => {
    setNotifications((prev) => {
      const diffNotifications = newNotifications.filter(c => prev.findIndex(x => x.id === c.id) === -1);

      if (diffNotifications.length === 0) {
        return prev;
      }

      return [...diffNotifications, ...prev];
    });
  }, []);

  const getOwnerNotifications = useCallback(async () => {
    setLoading(true);

    const { data, error } = await fetchNotifications(1, pageLimit);

    setLoading(false);

    setFetched(true);

    if (error) {
      setError(error.error);
      setNotifications([]);
      return;
    }

    if (data) {
      setNotifications(data.notifications);
      setHasNext(data.hasNext);
    }
  }, [pageLimit]);

  const loadMore = useCallback(async () => {
    setPageLimit((prev) => prev + initialPageLimit);
  }, []);

  const clearNotifications = useCallback(() => {
    setNotifications([]);
    setError(null);
    setFetched(false);
  }, []);

  // Make a GET request when the pageLimit increases
  useEffect(() => {
    if (pageLimit > initialPageLimit) {
      getOwnerNotifications();
    }
  }, [pageLimit, getOwnerNotifications]);

  const value = useMemo(
    () => ({
      notifications,
      error,
      fetched,
      hasNext,
      loading,
      setNotifications: updateNotificationsArray,
      getNotifications: getOwnerNotifications,
      clearNotifications,
      loadMore,
    }),
    [
      notifications,
      updateNotificationsArray,
      loadMore,
      getOwnerNotifications,
      clearNotifications,
      error,
      fetched,
      hasNext,
      loading,
    ]
  );

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export default NotificationsProvider;
