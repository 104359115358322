import { useContext } from "react";
import { LengthContext } from "../contexts/LengthContext";

export const useLength = () => {
  const context = useContext(LengthContext);
  if (!context) {
    throw new Error("useLength must be used within a LengthProvider");
  }
  return context;
};
