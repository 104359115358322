import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import Button from "./buttons/Button";
import {
  ROUTE_ADD_FIELD,
  ROUTE_BOOK_FIELD,
  ROUTE_CREATE_COMPLEX,
  ROUTE_EDIT_ARENA_INFO,
  ROUTE_EDIT_FIELD_INFO,
  ROUTE_HELP,
  ROUTE_LOGIN,
  ROUTE_MY_FIELDS,
  ROUTE_RESERVATION,
  ROUTE_SETTINGS,
  ROUTE_UPDATE_ARENA,
} from "../router/constants";
import NavbarDropdown from "./NavbarDropdown";
import { useLogout } from "../hooks/useLogout";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import SwitchLanguageButton from "./SwitchLanguageButton";
import NotificationsContainer from "./notifications/NotificationsContainer";


const Navbar = () => {
  const { logout } = useLogout();

  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const path = location.pathname;

  const menuItems = [
    {
      label: t(COMMON_TRANSLATE_KEYS.LOG_OUT),
      onClick: () => {
        logout();
        navigate(ROUTE_LOGIN);
      },
    },
  ];

  const sidebarItems = [
    {
      label: t(COMMON_TRANSLATE_KEYS.RESERVATION),
      onClick: () => navigate(ROUTE_RESERVATION),
    },
    {
      label: t(COMMON_TRANSLATE_KEYS.MY_FIELDS),
      onClick: () => navigate(ROUTE_MY_FIELDS),
    },
    {
      label: t(COMMON_TRANSLATE_KEYS.SETTINGS),
      onClick: () => navigate(ROUTE_SETTINGS),
    },
    {
      label: t(COMMON_TRANSLATE_KEYS.HELP),
      onClick: () => navigate(ROUTE_HELP),
    },
    {
      label: t(COMMON_TRANSLATE_KEYS.LOG_OUT),
      onClick: () => {
        logout();
        navigate(ROUTE_LOGIN);
      },
    },
  ];

  const { pageTitle, renderButtons } = useMemo(() => {
    let pageTitle = "";
    let renderButtons = false;

    if (matchPath(ROUTE_MY_FIELDS, path)) {
      pageTitle = t(COMMON_TRANSLATE_KEYS.MY_FIELDS);
      renderButtons = true;
    } else if (matchPath(ROUTE_BOOK_FIELD, path)) {
      pageTitle = t(COMMON_TRANSLATE_KEYS.BOOK_FIELD);
    } else if (matchPath(ROUTE_EDIT_ARENA_INFO + "/:id", path)) {
      pageTitle = t(COMMON_TRANSLATE_KEYS.EDIT_COMPLEX);
    } else if (matchPath(ROUTE_UPDATE_ARENA, path)) {
      pageTitle = t(COMMON_TRANSLATE_KEYS.ADD_FIELD); 
    } else if (matchPath(ROUTE_EDIT_FIELD_INFO + "/:complexId/:id", path)) {
      pageTitle = t(COMMON_TRANSLATE_KEYS.EDIT_FIELD);
    } else if (matchPath(ROUTE_SETTINGS, path)) {
      pageTitle = t(COMMON_TRANSLATE_KEYS.SETTINGS);
    } else if (matchPath(ROUTE_LOGIN, path)) {
      pageTitle = t(COMMON_TRANSLATE_KEYS.LOG_IN);
    } else if (matchPath(ROUTE_RESERVATION, path)) {
      pageTitle = t(COMMON_TRANSLATE_KEYS.RESERVATION);
    } else if (matchPath(ROUTE_CREATE_COMPLEX, path)) {
      pageTitle = t(COMMON_TRANSLATE_KEYS.CREATE_COMPLEX);
    } else if (matchPath(ROUTE_ADD_FIELD, path)) {
      pageTitle = t(COMMON_TRANSLATE_KEYS.ADD_FIELD);
    } else if (matchPath(ROUTE_BOOK_FIELD, path)) {
      pageTitle = t(COMMON_TRANSLATE_KEYS.BOOK_FIELD);
    } else {
      renderButtons = true;
    }

    return {
      pageTitle,
      renderButtons,
    };
  }, [path,t]);

  return (
    <div className="bg-mainBlack h-12 mr-[30px] ml-8 mt-[30px] flex justify-between items-center">
      <div>
        {renderButtons && (
          <>
            <span className="mr-2 text-base leading-5 font-medium font-inter">
              <Button
                backgroundColor="bg-mainGreen"
                width={12}
                textColor="text-mainBlack"
                onClick={() => navigate(ROUTE_ADD_FIELD)}
              >
                {t(COMMON_TRANSLATE_KEYS.ADD_FIELD)}
              </Button>
            </span>
            <span className="text-base leading-5 font-medium font-inter">
              <Button
                backgroundColor="bg-mainBlack"
                width={12}
                textColor="text-mainBlue"
                border="border-mainBlue"
                onClick={() => navigate(ROUTE_CREATE_COMPLEX)}
              >
                {t(COMMON_TRANSLATE_KEYS.CREATE_COMPLEX)}
              </Button>
            </span>
          </>
        )}
        {!renderButtons && (
          <h2 className="text-mainWhite text-lg leading-22px font-semibold">
            {pageTitle}
          </h2>
        )}
      </div>

      <div className="flex items-center">
        <SwitchLanguageButton />

        <NotificationsContainer containerClassName="px-3" />

        <div className="hidden md:flex">
          <NavbarDropdown
            items={menuItems}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div className="md:hidden">
          <NavbarDropdown
            items={sidebarItems}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
