import * as yup from "yup";
import * as ERRORS from "../../constants/errors";

export const resetPassSchema = yup.object().shape({
  password: yup.string().min(6, ERRORS.PASSWORD_IS_SHORT).required(ERRORS.PASSWORD_IS_REQUIRED),
  repeatPassword: yup.string().test({
    name: 'passwords-match',
    message: ERRORS.PASSWORDS_NOT_MATCH,
    test: (value, context) => context.parent.password === value,
  }).required(ERRORS.REPEAT_PASSWORD_IS_REQUIRED),
}).required();
