import * as yup from "yup";
import * as ERRORS from "../../constants/errors";

export const reservationHourLimitSchema = yup.object().shape({
  hours: yup
    .number()
    .integer()
    .typeError(ERRORS.RESERVATION_LIMIT_IS_INVALID)
    .max(48, ERRORS.RESERVATION_LIMIT_MAX_MIN)
    .min(2, ERRORS.RESERVATION_LIMIT_MAX_MIN)
    .required(ERRORS.RESERVATION_LIMIT_IS_REQUIRED)
}).required();
