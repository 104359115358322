import * as yup from "yup";
import * as ERRORS from "../../constants/errors";
import { PHONE_REGEX } from "../../utils/regex";

export const profileSchema = yup.object().shape({
  name: yup.string().min(3, ERRORS.NAME_IS_SHORT).required(ERRORS.NAME_IS_REQUIRED),
  email: yup.string().email(ERRORS.EMAIL_IS_INVALID).required(ERRORS.EMAIL_IS_REQUIRED),
  phone: yup
    .string()
    .matches(PHONE_REGEX, { message: ERRORS.PHONE_IS_INVALID, excludeEmptyString: true })
    .min(5, ERRORS.PHONE_IS_INVALID)
    .max(15, ERRORS.PHONE_IS_INVALID)
    .required(ERRORS.PHONE_IS_REQUIRED),
  password: yup.string().optional(),
}).required();
