const tokenKey = 'token';

const msgTokenKey = 'msg-token';

export const setToken = (token: string) => {
  localStorage.setItem(tokenKey, token);
};

export const getToken = () => localStorage.getItem(tokenKey);

export const clearToken = () => localStorage.removeItem(tokenKey);

export const setMsgToken = (token: string) => {
  localStorage.setItem(msgTokenKey, token);
};

export const getMsgToken = () => localStorage.getItem(msgTokenKey);

export const clearMsgToken = () => localStorage.removeItem(msgTokenKey);