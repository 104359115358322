import React, { useCallback } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../hooks/useAuth";

interface NavbarDropdownProps {
  items: Array<{ label: string; onClick: () => void }>;
  selectedOption: string | null;
  setSelectedOption: React.Dispatch<React.SetStateAction<string | null>>;
  showIcon?: boolean;
}

const NavbarDropdown: React.FC<NavbarDropdownProps> = ({
  items,
  selectedOption,
  setSelectedOption,
  showIcon = true,
}) => {
  const { user } = useAuth();

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    items.find((item) => item.label === option)?.onClick();
  };

  const renderProfileImg = useCallback(() => {
    if (user) {
      const { imageUrl } = user;

      if (imageUrl) {
        return (
          <img
            src={imageUrl}
            alt="profile"
            className="mr-4 rounded-full w-[36px] h-[36px] object-cover"
            width={36}
            height={36}
          />
        );
      }
    }

    return (
      <div className="w-[36px] h-[36px] mr-4 rounded-full bg-mainBlack flex-shrink-0"></div>
    );
  }, [user]);

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="flex items-center text-mainWhite text-base font-semibold bg-additionalBlack max-w-[100rem] rounded-full py-2 ps-2 pe-4">
        {showIcon && renderProfileImg()}
        <p className="mr-1 leading-22.4px font-inter text-sm font-medium text-ellipsis overflow-hidden text-nowrap min-w-16">
          {user?.name}
        </p>
        <ChevronDownIcon className="w-[16px] h-[16px] text-mainWhite flex-shrink-0" />
      </Menu.Button>
      <Menu.Items className="absolute right-0 w-full mt-2 origin-top-right bg-additionalBlack border border-gray-200 divide-y divide-gray-100 rounded-md text-mainWhite z-50">
        {items.map((item, index) => (
          <Menu.Item key={index}>
            {({ active }) => (
              <button
                className={`${
                  active ? "bg-mainWhite text-mainBlack" : ""
                } group flex rounded-md items-center w-full p-2 text-sm`}
                onClick={() => handleOptionClick(item.label)}
              >
                {item.label}
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default NavbarDropdown;
