import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { User } from "../types/auth";
import { clearToken, getToken, setToken } from "../utils/token";
import { getOwnerDetails } from "../api/owner";
import { useFirebaseMessaging } from "../hooks/useFirebaseMessaging";

type TProps = {
  children: ReactNode;
};

type AuthContextValue = {
  user: User | null;
  login: (accessToken: string, user: User, initNotifications?: boolean) => Promise<void>;
  updateUser: (userData: User) => void;
  logoutUser: () => void;
  isAuthenticated: () => boolean;
};

export const AuthContext = createContext<AuthContextValue>({
  user: null,
  login: async () => undefined,
  updateUser: () => undefined,
  logoutUser: () => undefined,
  isAuthenticated: () => false,
});

// The image url doesn't change when calling PUT /owner. Only the content at that address.
// The browser caches the image, so we use a query parameter to trigger the rerender
const refreshImageBrowserCache = (userImage: string | null) =>
  userImage ? `${userImage}?t=${Date.now()}` : userImage;

const AuthProvider = ({ children }: TProps) => {
  const [user, setUser] = useState<User | null>(null);

  const { initializeNotifications } = useFirebaseMessaging();

  const navigate = useNavigate();

  const login = useCallback(async (accessToken: string, user: User, initNotifications = false) => {
    setUser({ ...user, imageUrl: refreshImageBrowserCache(user.imageUrl) });
    setToken(accessToken);

    if (initNotifications) {
      await initializeNotifications();
    }
  }, [initializeNotifications]);

  const logout = useCallback(() => {
    setUser(null);
    clearToken();
    navigate("/", { replace: true });
  }, [navigate]);

  const updateUser = useCallback((data: User) => {
    setUser({
      ...data,
      imageUrl: refreshImageBrowserCache(data.imageUrl),
    });
  }, []);

  useEffect(() => {
    const tokenInStorage = getToken();

    if (tokenInStorage && !user) {
      getOwnerDetails(tokenInStorage)
        .then(({ data, error }) => {
          if (data) {
            login(tokenInStorage, data);
          }

          if (error) {
            logout();
          }
        })
        .catch(() => {
          logout();
        });
    }
  }, [user, login, logout]);

  const isAuthenticated = useCallback(() => !!getToken() || !!user, [user]);

  const value = useMemo(
    () => ({
      user,
      login,
      logoutUser: logout,
      isAuthenticated,
      updateUser,
    }),
    [user, login, logout, updateUser, isAuthenticated]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
