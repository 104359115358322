import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { PricePerHour, MenuItem } from "../../types";
import DropdownMenu from "../DropdownMenu";
import { usePricePerHour } from "../../hooks/usePricePerHour";

type TProps = {
  selectedPrice: MenuItem<PricePerHour> | null;
  setSelectedPrice: Function;
  width?: number;
  height?: number;
  placeholder?: string;
  error?: string;
  showIcon?: boolean;
};

const PricePerHourDropdown = ({
  selectedPrice,
  setSelectedPrice,
  ...rest
}: TProps) => {
  const { prices, error, getPrices } = usePricePerHour();

  const priceOptions = useMemo(() => {
    return prices.map((p) => {
      const item = {
        label: `${p.value}`,
        value: p,
        onClick: () => undefined,
      };

      item.onClick = () => setSelectedPrice(item);

      return item;
    });
  }, [prices, setSelectedPrice]);

  const selectedOption = useMemo(() => {
    if (!selectedPrice) {
      return null;
    }

    const selected = { ...selectedPrice };
    selected.label = `${selectedPrice.value.value}`;
    return selected;
  }, [selectedPrice]);

  useEffect(() => {
    getPrices();
  }, [getPrices]);

  useEffect(() => {
    if (error) {
      toast(error.error, {
        toastId: "fetch-prices-error",
        theme: "dark",
        className: "text-center",
        bodyClassName: "custom-toast-body",
      });
    }
  }, [error]);

  return (
    <DropdownMenu<PricePerHour>
      items={priceOptions}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedPrice}
      {...rest}
    />
  );
};

export default PricePerHourDropdown;
