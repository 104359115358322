import axios, { AxiosError } from "axios";

import {
  Owner,
  UpdateOwnerDetailsPayload,
  UpdateSettingsPayload,
} from "../types/owner";
import { getHeaders } from "./utils/getHeader";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";

type GetOwnerResultWithError = {
  data?: Owner;
  error?: any;
};

export const getOwnerDetails = async (
  token: string
): Promise<GetOwnerResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/owner`;

  let result: GetOwnerResultWithError = {};

  try {
    const response = await axios.get(url, {
      headers: getHeaders(true, token),
    });

    result.data = response.data as Owner;
  } catch (error) {
    const { response } = error as AxiosError;
    result.error = response?.data;
  }

  return result;
};

export const updateOwnerDetails = async (
  payload: UpdateOwnerDetailsPayload
): Promise<GetOwnerResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/owner`;

  let result: GetOwnerResultWithError = {};

  try {
    const response = await axios.put(url, payload, {
      headers: getHeaders(true),
    });

    result.data = response.data as Owner;
  } catch (error) {
    const { response } = error as AxiosError;

    result.error = response?.data;
  }

  return result;
};
export const updateSettings = async (
  payload: UpdateSettingsPayload,
  t: (key: string) => string
): Promise<GetOwnerResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/owner/settings`;

  let result: GetOwnerResultWithError = {};

  try {
    const response = await axios.put(url, payload, {
      headers: getHeaders(true),
    });

    result.data = response.data as Owner;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { response } = error;

      result.error = response?.data || {
        message: `${t(COMMON_TRANSLATE_KEYS.UNEXPECTED_ERROR)}`,
      };
    } else {
      result.error = {
        message: `${t(COMMON_TRANSLATE_KEYS.UNEXPECTED_ERROR)}`,
      };
    }
  }

  return result;
};
