import { createContext, ReactNode, useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";

import { AvailableReservationDateSlots, AvailableReservationTimeSlot, GetAvailableReservationSlotsResult } from "../types/reservations";
import { fetchAvailableReservationSlots } from "../api/reservations";
import { getDateForCalendar } from "../utils/dates";

type TProps = {
  children: ReactNode;
};

type AvailableDatesCache = {
  [key: string]: Date[];
};

type AvailableTimesCache = {
  [key: string]: AvailableReservationDateSlots[];
};

type AvailableReservationsContextValue = {
  getDates: (fieldId?: number) => Date[];
  getTimeSlotsForDate: (fieldId?: number, date?: Date) => AvailableReservationTimeSlot[];
  fetch: (fieldId: number, month: number, year: number, skipCache?: boolean) => Promise<{ error: any }>;
  clear: () => void;
  error: any;
  fetched: boolean;
};

export const AvailableReservationsContext = createContext<AvailableReservationsContextValue>({
  getDates: () => [],
  getTimeSlotsForDate: () => [],
  fetch: async () => ({ error: null }),
  clear: () => undefined,
  error: null,
  fetched: false,
});

const AvailableReservationsProvider = ({ children }: TProps) => {
  const [availableDates, setDates] = useState<AvailableDatesCache>({});
  const [availableTimeSlots, setAvailableTimeSlots] = useState<AvailableTimesCache>({});
  const [error, setError] = useState<any>(null);
  const [fetched, setFetched] = useState(false);

  const getAvailableReservationSlots = useCallback(async (fieldId: number, month: number, year: number, skipCache = false) => {
    let data: GetAvailableReservationSlotsResult | undefined;
    let error: any;

    const timesCacheKey = `${fieldId}-${month}-${year}`;
    const datesCacheKey = `${fieldId}`;

    if (skipCache) {
      const result = await fetchAvailableReservationSlots(fieldId, month, year);

      data = result.data;
      error = result.error;
    } else {
      if (Object.keys(availableTimeSlots).includes(timesCacheKey)) {
        return { error: null };
      }

      const result = await fetchAvailableReservationSlots(fieldId, month, year);

      data = result.data;
      error = result.error;
    }

    setFetched(true);

    if (error) {
      setError(error.error);
      return { error: error.error };
    }

    if (data) {
      setAvailableTimeSlots((prev) => ({
        ...prev,
        [timesCacheKey]: data ? data.filter(d => d.isAvailable) : [],
      }));
      setDates((prev) => ({
        ...prev,
        [datesCacheKey]: data ? [...(prev[datesCacheKey] || []), ...data.filter(d => d.isAvailable).map(d => getDateForCalendar(d.date))] : prev[datesCacheKey],
      }));
    }

    return { error: null };
  }, [availableTimeSlots]);

  const clear = useCallback(() => {
    setDates({});
    setAvailableTimeSlots({});
  }, []);

  const getTimeSlotsForDate = useCallback((fieldId?: number, date?: Date) => {
    if (!fieldId || !date) {
      return [];
    }

    const month = date.getMonth();
    const year = date.getFullYear();
    const cacheKey = `${fieldId}-${month}-${year}`;

    return availableTimeSlots[cacheKey]?.find(d => dayjs(d.date).isSame(date, 'date'))?.availableSlots || [];
  }, [availableTimeSlots]);

  const getDates = useCallback((fieldId?: number) => {
    if (!fieldId) {
      return [];
    }

    const cacheKey = `${fieldId}`;

    return availableDates[cacheKey]
  }, [availableDates]);

  const value = useMemo(
    () => ({
      error,
      fetched,
      getDates,
      getTimeSlotsForDate,
      clear,
      fetch: getAvailableReservationSlots,
    }),
    [error, fetched, getDates, getTimeSlotsForDate, getAvailableReservationSlots, clear]
  );

  return <AvailableReservationsContext.Provider value={value}>{children}</AvailableReservationsContext.Provider>;
};

export default AvailableReservationsProvider;
