import { createContext, useState, useEffect, ReactNode } from "react";
import { fetchLengths } from "../api/length";
import { Length } from "../types";

interface LengthContextType {
  lengths: Length[];
  error: any;
  getLengths: () => void;
}

export const LengthContext = createContext<LengthContextType | undefined>(undefined);

export const LengthProvider = ({ children }: { children: ReactNode }) => {
  const [lengths, setLengths] = useState<Length[]>([]);
  const [error, setError] = useState<any>(null);

  const getLengths = async () => {
    const result = await fetchLengths();
    if (result.error) {
      setError(result.error);
    } else {
      setLengths(result.data ?? []);
    }
  };

  useEffect(() => {
    getLengths();
  }, []);

  return (
    <LengthContext.Provider value={{ lengths, error, getLengths }}>
      {children}
    </LengthContext.Provider>
  );
};
