import { ToastContainer } from "react-toastify";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import i18n from "./i18next";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat'
import updateLocale from 'dayjs/plugin/updateLocale'

import Wrapper from "./components/Wrapper";
import {
  ROUTE_SETTINGS,
  ROUTE_LOGIN,
  ROUTE_MY_FIELDS,
  ROUTE_RESERVATION,
  ROUTE_CREATE_COMPLEX,
  ROUTE_ADD_FIELD,
  ROUTE_UPDATE_ARENA,
  ROUTE_ARENA_INFO,
  ROUTE_ARENA_FIELDS,
  ROUTE_ARENA_FIELD_RESERVATION,
  ROUTE_ARENA_CALENDAR_RESERVATION,
  ROUTE_BOOK_FIELD,
  ROUTE_GOOGLE_GET_TOKEN,
  ROUTE_NOT_FOUND,
  ROUTE_REGISTER,
  ROUTE_HELP,
  ROUTE_FORGOT_PASS,
  ROUTE_RESET_PASS,
  ROUTE_HOME,
  ROUTE_ROOT,
  ROUTE_EDIT_ARENA_INFO,
  ROUTE_EDIT_FIELD_INFO,
} from "./router/constants";

import Settings from "./pages/settings-page/Settings";
import LoginPage from "./pages/LoginPage";
import MyFields from "./pages/MyFields";
import ReservationPage from "./pages/Reservation";
import CreateComplex from "./pages/CreateComplex";
import ArenaInfo from "./pages/ArenaInfo";
import AddField from "./pages/AddField";
import ArenaFields from "./pages/ArenaFields";
import ArenaFieldReservation from "./pages/ArenaFieldReservation";
import ArenaCalendar from "./pages/ArenaCalendar";
import BookField from "./pages/BookField";
import GoogleRedirectPage from "./pages/GoogleRedirect";
import ProtectedRoute from "./router/ProtectedRoute";
import NotFound from "./pages/NotFound";
import RegisterPage from "./pages/RegisterPage";
import HelpPage from "./pages/Help";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ResetPassword";
import EditArenaInfo from "./pages/EditArenaInfo";
import EditFieldInfo from "./pages/EditFieldInfo";

import ContextsWrapper from "./contexts";

import "react-toastify/dist/ReactToastify.css";

const authenticated = (element: JSX.Element) => (
  <ProtectedRoute>{element}</ProtectedRoute>
);

function App() {
  dayjs.extend(localizedFormat);
  dayjs.extend(updateLocale);

  dayjs.updateLocale('bg', {
    formats: {
      LLLL: 'ddd, DD MMM',
      LL: 'ddd, DD MMMM, YYYY',
    },
  });

  dayjs.updateLocale('en', {
    formats: {
      LLLL: 'ddd, DD MMM',
      LL: 'ddd, DD MMMM, YYYY',
    },
    weekStart: 1,
  });

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <ToastContainer
            closeButton
            autoClose={5000}
            position="bottom-right"
            theme="dark"
            bodyClassName="text-center"
            hideProgressBar
          />

          <ContextsWrapper>
            <Wrapper>
              <Routes>
                <Route
                  path={ROUTE_ROOT}
                  element={<Navigate to={ROUTE_HOME} replace />}
                />
                <Route path={ROUTE_LOGIN} element={<LoginPage />} />
                <Route path={ROUTE_REGISTER} element={<RegisterPage />} />
                <Route
                  path={ROUTE_GOOGLE_GET_TOKEN}
                  element={<GoogleRedirectPage />}
                />
                <Route path={ROUTE_HELP} element={<HelpPage />} />
                <Route
                  path={ROUTE_FORGOT_PASS}
                  element={<ForgotPasswordPage />}
                />
                <Route
                  path={ROUTE_RESET_PASS}
                  element={<ResetPasswordPage />}
                />

                <Route
                  path={ROUTE_BOOK_FIELD}
                  element={authenticated(<BookField />)}
                />
                <Route
                  path={ROUTE_UPDATE_ARENA}
                  element={authenticated(<AddField />)}
                />
                <Route
                  path={ROUTE_SETTINGS}
                  element={authenticated(<Settings />)}
                />
                <Route
                  path={ROUTE_MY_FIELDS}
                  element={authenticated(<MyFields />)}
                />
                <Route
                  path={ROUTE_RESERVATION}
                  element={authenticated(<ReservationPage />)}
                />
                <Route
                  path={ROUTE_CREATE_COMPLEX}
                  element={authenticated(<CreateComplex />)}
                />
                <Route
                  path={ROUTE_ADD_FIELD}
                  element={authenticated(<AddField />)}
                />
                <Route
                  path={ROUTE_UPDATE_ARENA + "/:id"}
                  element={authenticated(<AddField />)}
                />
                <Route
                  path={ROUTE_ARENA_INFO}
                  element={authenticated(<ArenaInfo />)}
                />
                <Route
                  path={ROUTE_ARENA_FIELDS}
                  element={authenticated(<ArenaFields />)}
                />
                <Route
                  path={ROUTE_ARENA_FIELD_RESERVATION}
                  element={authenticated(<ArenaFieldReservation />)}
                />
                <Route
                  path={ROUTE_ARENA_CALENDAR_RESERVATION}
                  element={authenticated(<ArenaCalendar />)}
                />

                <Route
                  path={ROUTE_EDIT_ARENA_INFO + "/:id"}
                  element={authenticated(<EditArenaInfo />)}
                />
                <Route
                  path={ROUTE_EDIT_FIELD_INFO + "/:complexId/:id"}
                  element={authenticated(<EditFieldInfo />)}
                />
                <Route path={ROUTE_NOT_FOUND} element={<NotFound />} />
                <Route path="*" element={<Navigate to={ROUTE_NOT_FOUND} />} />
              </Routes>
            </Wrapper>
          </ContextsWrapper>
        </BrowserRouter>
      </I18nextProvider>
    </>
  );
}

export default App;
