export const ROUTE_ROOT = "/";
export const ROUTE_SETTINGS = "/settings";
export const ROUTE_HELP = "/help";
export const ROUTE_LOGIN = "/login";
export const ROUTE_REGISTER = "/register";
export const ROUTE_FORGOT_PASS = "/forgot-password";
export const ROUTE_RESET_PASS = "/reset-password";
export const ROUTE_GOOGLE_GET_TOKEN = "/oauth/google/callback";
export const ROUTE_MY_FIELDS = "/my-fields";
export const ROUTE_RESERVATION = "/reservation";
export const ROUTE_CREATE_COMPLEX = "/create-complex";
export const ROUTE_ADD_FIELD = "/add-field";
export const ROUTE_BOOK_FIELD = "/book-field/:complexId/:fieldId";
export const ROUTE_UPDATE_ARENA = "/arena/add-field/:id";
export const ROUTE_ARENA_INFO = "/arena/:id/info";
export const ROUTE_ARENA_FIELDS = "/arena/:id/fields";
export const ROUTE_ARENA_FIELD_RESERVATION =
  "/arena/:id/field-reservation/:fieldId";
export const ROUTE_ARENA_CALENDAR_RESERVATION =
  "/arena/:id/field/:fieldId/calendar";
export const ROUTE_HOME = ROUTE_MY_FIELDS;
export const ROUTE_EDIT_INFO = "/edit-info";
export const ROUTE_EDIT_ARENA_INFO = "/edit-arena-info";
export const ROUTE_EDIT_FIELD_INFO = "/edit-field-info";
export const ROUTE_NOT_FOUND = "/not-found";
