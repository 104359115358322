const DarkenBackground = () => (
    <>
      <div
      className="absolute inset-0 z-0"
      style={{
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0 0 0 / 84%) 80%, rgb(0 0 0 / 72%) 100%), url(/login-background.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      >
      </div>
  <div className="absolute inset-0 z-10 bg-[#222223] opacity-80"></div></>
);

export default DarkenBackground;