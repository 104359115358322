import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import InputField from "../../../components/InputField";
import Button from "../../../components/buttons/Button";
import { reservationHourLimitSchema } from "../../../validation/schemas/reservation";
import { useAuth } from "../../../hooks/useAuth";
import { updateSettings } from "../../../api/owner";
import { COMMON_TRANSLATE_KEYS } from "../../../constants/translate-keys";

interface IFormInput {
  hours: number;
}

const ReservationTab = () => {
  const { user, updateUser } = useAuth();

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    formState: {
      errors,
      isValid,
      // isDirty,
      // isSubmitSuccessful,
      isLoading: isFormSending,
    },
  } = useForm<IFormInput>({
    defaultValues: {
      hours: user?.cancelReservationHours || 12,
    },
    resolver: yupResolver(reservationHourLimitSchema),
    mode: "onChange",
  });

  const handleSave = useCallback(
    async (input: IFormInput) => {
      try {
        const { data, error } = await updateSettings(input,t);

        if (error) {
          console.error("API Error: ", error.error);
          toast(error.error.message, {
            toastId: "update-owner",
          });
          return;
        }

        if (data) {
          updateUser(data);

          reset(
            {
              hours: data.cancelReservationHours ?? undefined,
            },
            {
              keepDirty: true,
              keepDirtyValues: false,
              keepValues: true,
            }
          );
          toast.success(`${t(COMMON_TRANSLATE_KEYS.CANCEL_DEADLINE_UPDATE)}`, {
            theme: "dark",
            className: "text-center",
            bodyClassName: "custom-toast-body",
            toastId: "update-success",
          });
        }
      } catch (err) {
        toast(`${t(COMMON_TRANSLATE_KEYS.UNEXPECTED_ERROR)}`, {
          toastId: "update-owner",
        });
      }
    },
    [updateUser, reset, t]
  );

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-2/5 gap-5">
        <p>{t(COMMON_TRANSLATE_KEYS.CANCEL_DEADLINE_TEXT)}</p>
        <form onSubmit={handleSubmit(handleSave)} className="flex flex-col">
          <label>{t(COMMON_TRANSLATE_KEYS.HOURS, { count: 2 })}</label>
          <div className="flex flex-row justify-between mt-4">
            <div className="flex flex-col">
              <InputField
                placeholder={t(COMMON_TRANSLATE_KEYS.HOURS)}
                {...register("hours")}
                width={10}
                height={3}
              />
              <div className="h-5 mt-2">
                {errors.hours && (
                  <span className="text-red-500 text-sm">
                    {t(errors.hours.message || "")}
                  </span>
                )}
              </div>
            </div>
            {/* TO-DO  isDisabled={
                  !isValid || isFormSending || (isSubmitSuccessful && isDirty)
                } */}
            <Button
              type="submit"
              height={3}
              width={10}
              border="border"
              borderColor="border-mainBlue"
              textColor="text-mainBlue"
              className="max-w-40 self-start min-w-6"
              isDisabled={!isValid || isFormSending}
            >
              {t(COMMON_TRANSLATE_KEYS.SAVE)}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReservationTab;
