import React, { ReactNode, useMemo } from "react";
import ImageCarousel from "../carousel/ImageCarousel";
import { Field } from "../../types";
import { getFileNameFromUrl } from "../../utils/files";

interface FieldCardProps {
  title: string;
  location: string;
  children: ReactNode;
  width?: "auto" | "sm" | "md" | "lg" | "xl";
  id?: number;
  field: Field;
}

const FieldCard: React.FC<FieldCardProps> = ({
  title,
  location,
  children,
  width = "",
  field,
  id,
}) => {
  const getWidthClass = (width: string) => {
    switch (width) {
      case "sm":
        return "w-40";
      case "md":
        return "w-96";
      case "lg":
      case "xl":
        return "w-full";
      default:
        return "";
    }
  };

  const carouselImages = useMemo(() => {
    return field?.imageUrls.map((src) => ({
      id: getFileNameFromUrl(src, 'field'),
      src: src,
    })) || [];
  }, [field]);

  return (
    <div
      className={`relative overflow-hidden rounded-2xl ${getWidthClass(
        width
      )} bg-secondaryBlack`}
    >
      {width === "xl" ? (
        <>
          <div
            className={`relative rounded-lg bg-secondaryBlack shadow-lg ${getWidthClass(
              width
            )}`}
          >
            {carouselImages.length > 0 && (
              <div className="relative flex flex-col">
                <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-secondaryBlack via-semi-transparent to-transparent z-10" />
                <ImageCarousel
                  images={carouselImages}
                  containerClassName="relative"
                  itemClassName="w-[450px] h-[300px] p-5"
                  itemsShown={{
                    desktop: 3,
                  }}
                  showDots={false}
                  readonly
                />
              </div>
            )}
            <div className="flex flex-col h-full" style={{ paddingTop: carouselImages.length === 0 ? '300px' : 0 }}>
              <h2 className="text-6xl text-mainWhite font-bold mb-2 pl-4">
                {title}
              </h2>
              <div className="p-4 text-mainWhite flex flex-row justify-between ">
                <span className="text-base">
                  <p>{location}</p>
                  <p>{field?.address}</p>
                </span>
                {children}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
        
        <div className="relative w-full h-[200px]">
           {carouselImages.length > 0 && (
               <div className="relative overflow-hidden rounded-t-lg">
                 <ImageCarousel
                   images={carouselImages}
                   containerClassName="absolute"
                   itemClassName="h-[200px]"
                   itemsShown={{
                     desktop: 1,
                   }}
                   showDots={false}
                   readonly
                 />
                 <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-secondaryBlack via-semi-transparent to-transparent z-10" />
               </div>
             )}
         </div>
         <div className="p-4 text-mainWhite flex flex-col">
           <div className="flex flex-row justify-between">
             <div>
               <h2 className="text-xl font-bold mb-2 ">{title}</h2>
               <p className="text-sm mb-2">{location}</p>
             </div>
           </div>
           <div className="flex flex-row justify-center">
             {children}
           </div>
         </div>
        </>
      )}
    </div>
  );
};

export default FieldCard;
