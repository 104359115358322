import * as yup from 'yup';

import * as ERRORS from "../../constants/errors";

export const addFieldSchema = yup.object().shape({
  fieldName: yup.string().required(ERRORS.FIELD_NAME_IS_REQUIRED).min(3, ERRORS.FIELD_NAME_IS_SHORT),
  complexId: yup.number().min(1, ERRORS.COMPLEX_IS_REQUIRED).required(ERRORS.COMPLEX_IS_REQUIRED),
  currencyId: yup.number().min(1, ERRORS.CURRENCY_IS_REQUIRED).required(ERRORS.CURRENCY_IS_REQUIRED),
  sizeId: yup.number().min(1, ERRORS.SIZE_IS_REQUIRED).required(ERRORS.SIZE_IS_REQUIRED),
  floorId: yup.number().min(1, ERRORS.FLOOR_IS_REQUIRED).required(ERRORS.FLOOR_IS_REQUIRED),
  priceId: yup.number().min(1, ERRORS.PRICE_IS_REQUIRED).required(ERRORS.PRICE_IS_REQUIRED),
  lengthId: yup.number().min(1, ERRORS.LENGTH_IS_REQUIRED).required(ERRORS.LENGTH_IS_REQUIRED),
  widthId: yup.number().min(1, ERRORS.WIDTH_IS_REQUIRED).required(ERRORS.WIDTH_IS_REQUIRED),
});