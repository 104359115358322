import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC6I7t4QK5p3bf4wsG8hXqTFxNI2ecPMFk",
  authDomain: "bolarena-21c83.firebaseapp.com",
  projectId: "bolarena-21c83",
  storageBucket: "bolarena-21c83.appspot.com",
  messagingSenderId: "406550146631",
  appId: "1:406550146631:web:f27adc5cbed8c06312cb62",
  measurementId: "G-5R0FE6SEEG"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const messaging = getMessaging(app);

export default app;