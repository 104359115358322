import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import { useAuth } from "../hooks/useAuth";

type TProps = {
  ownerText: string;
};

const OwnerBar = ({ ownerText }: TProps) => {
  const { user } = useAuth();

  const { t } = useTranslation();

  const renderIcon = useCallback(() => {
    if (user) {
      const { imageUrl } = user;

      if (imageUrl) {
        return (
          <img
            src={imageUrl}
            alt="profile"
            className="mr-4 rounded-full w-[60px] h-[60px] object-cover"
            width={36}
            height={36}
          />
        );
      }
    }

    return (
      <div className="mr-4 rounded-full w-[60px] h-[60px] border border-gray-400 bg-mainBlack"></div>
    );
  }, [user]);

  return (
    <div className="flex flex-row pb-4 items-center">
      {renderIcon()}
      <span>
        <p className="text-sm">{user?.name}</p>
        <p className="text-textGray text-xs">{ownerText}</p>
      </span>
    </div>
  )
};

export default OwnerBar;