import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import DropdownMenu from "../components/DropdownMenu";
import Divider from "../components/Divider";
import { Complex, MenuItem, ReservationStatuses } from "../types";
import ReservationCard from "../components/cards/ReservationCard";
import { useComplexes } from "../hooks/useComplexes";
import { getComplexReservations } from "../api/reservations";
import { Reservation } from "../types/reservations";
import { Player } from "../types/players";
import ReservationDetailsModal from "../components/modals/ReservationDetailsModal";
import PlayerDetailsModal from "../components/modals/PlayerDetailsModal";
import StatusBadge from "../components/StatusBadge";
import Button from "../components/buttons/Button";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";

interface ReservationsForComplex {
  complexId: number;
  reservations: Reservation[];
  hasNext: boolean;
};

const initialPageLimit = 10;

const ReservationPage = () => {
  const [selectedStatus, setSelectedStatus] =
    useState<MenuItem<ReservationStatuses> | null>(null);
  const [selectedComplex, setSelectedComplex] =
    useState<MenuItem<Complex> | null>(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSecondDrawerOpen, setSecondDrawerOpen] = useState(false);

  const [reservations, setReservations] = useState<ReservationsForComplex[]>([]);
  const [currentReservation, setCurrentReservation] = useState<Reservation | null>(null);
  const [currentPlayer, setCurrentPlayer] = useState<Player | null>(null);
  const [pageLimit, setPageLimit] = useState(initialPageLimit);
  const [loading, setLoading] = useState(false);

  const { complexes } = useComplexes();

  const { t } = useTranslation();

  const fetchReservationsForComplex = useCallback(async (complexId?: number, status?: string, currentPageLimit?: number) => {
    if (!complexId) {
      return;
    }

    setLoading(true);

    const { data, error } = await getComplexReservations(
      complexId,
      1,
      currentPageLimit || pageLimit,
      status || selectedStatus?.value,
      false, // TODO: Should be dynamic
      true,
    );

    setLoading(false);

    if (error) {
      const { error: errMsg } = error;
      toast(errMsg, {
        toastId: 'fetch-reservations-err',
      });

      return;
    }

    if (data) {
      setReservations((prev) => {
        const currentComplexIndex = prev.findIndex(r => r.complexId === complexId);

        if (currentComplexIndex >= 0) {
          prev[currentComplexIndex] = { complexId, ...data };
        } else {
          prev.push({
            complexId,
            ...data,
          });
        }

        return [...prev];
      })
    }
  }, [selectedStatus?.value, pageLimit]);

  const onLoadMoreClick = useCallback(async () => {
    const newPageLimit = pageLimit + initialPageLimit;
    setPageLimit(newPageLimit);
    
    await fetchReservationsForComplex(selectedComplex?.value.id, selectedStatus?.value, newPageLimit);
  }, [fetchReservationsForComplex, selectedComplex?.value.id, selectedStatus?.value, pageLimit]);

  const complexMenuOptions = useMemo(() => {
    if (complexes.length === 0) {
      return [{
        label: "No complexes found",
        value: null,
        isDisabled: true,
        onClick: () => undefined,
      }];
    }

    return complexes.map(c => ({
      label: c.name,
      value: c,
      onClick: async () => {
        const index = reservations.findIndex(x => x.complexId === c.id);
        
        if (index >= 0 && !selectedStatus) {
          return;
        }

        await fetchReservationsForComplex(c.id);
      } 
    }))
  }, [complexes, fetchReservationsForComplex, reservations, selectedStatus]);

  const statusMenuOptions = useMemo(() =>
    [
      {
        label: <span><StatusBadge status={'PENDING'} className="border-none" style={{ margin: 0 }} /></span>,
        value: ReservationStatuses.Pending,
        className: 'text-mainBlue',
      },
      {
        label: <span><StatusBadge status={'DECLINED'} className="border-none" style={{ margin: 0 }} /></span>,
        value: ReservationStatuses.Declined,
        className: 'text-cancel',
      },
      {
        label: <span><StatusBadge status={'CONFIRMED'} className="border-none" style={{ margin: 0 }} /></span>,
        value: ReservationStatuses.Accepted,
        className: 'text-functionalGreen',
      },
    ].map(o => ({
      ...o,
      onClick: async () => {
        if (selectedComplex) {
          await fetchReservationsForComplex(selectedComplex.value.id, o.value);
        }
      }
    })),
    [fetchReservationsForComplex, selectedComplex],
  );

  const handleReservationClick = useCallback((reservationId: number, complexId: number) => {
    const reservation = reservations.find(r => r.complexId === complexId)?.reservations.find(r => r.id === reservationId);

    if (!reservation) {
      setCurrentReservation(null);
      return;
    }

    setCurrentReservation(reservation);
    openDrawer();
  }, [reservations]);

  const handlePlayerClick = useCallback((player: Player) => {
    setCurrentPlayer(player);
    openSecondDrawer();
  }, []);

  const renderReservationCards = useCallback(() => {
    if (!selectedComplex) {
      return <h2 className="text-textGray text-xl">{t(COMMON_TRANSLATE_KEYS.COMPLEX_SELECT)}</h2>;
    }

    const currentComplexIndex = reservations.findIndex(r => r.complexId === selectedComplex.value.id);

    if (currentComplexIndex >= 0) {
      const pendingReservations = reservations[currentComplexIndex].reservations.filter(r => r.status === 'PENDING');
      const otherStatusReservations = reservations[currentComplexIndex].reservations.filter(r => r.status !== 'PENDING');

      const pendingReservationsCards = pendingReservations.map(r => (
        <ReservationCard
          key={r.id}
          fetchReservations={() => fetchReservationsForComplex(selectedComplex.value.id)}
          field={r.field}
          reservation={r}
          onClick={() => handleReservationClick(r.id, selectedComplex.value.id)}
        />
      ));

      const otherStatusReservationsCards = otherStatusReservations.map(r => (
        <ReservationCard
          key={r.id}
          fetchReservations={() => fetchReservationsForComplex(selectedComplex.value.id)}
          field={r.field}
          reservation={r}
          onClick={() => handleReservationClick(r.id, selectedComplex.value.id)}
        />
      ));

      const reservationCardContainerClassName = 'grid grid-cols-1 lg:grid-cols-2 gap-5 text-white';

      return (
        <div className="flex flex-col my-5">
          <div className={reservationCardContainerClassName}>
            {pendingReservationsCards}
          </div>

          {!selectedStatus && <Divider mb={4} mt={4} />}

          <div className={reservationCardContainerClassName}>
            {otherStatusReservationsCards}
          </div>

          {reservations[currentComplexIndex]?.hasNext && (
            <Button
              onClick={onLoadMoreClick}
              isDisabled={loading}
            >
              {t(COMMON_TRANSLATE_KEYS.LOAD_MORE)}
            </Button>
          )}
        </div>
      )
    }
  }, [
    fetchReservationsForComplex,
    handleReservationClick,
    loading,
    onLoadMoreClick,
    reservations,
    selectedComplex,
    selectedStatus,
    t,
  ]);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  const openSecondDrawer = () => setSecondDrawerOpen(true);
  const closeSecondDrawer = () => setSecondDrawerOpen(false);

  return (
    <>
      <div className="mx-[30px] mt-8">
        <div className="flex flex-row text-mainWhite justify-between">
          <div className="flex flex-row items-center">
            <span className="text-textGray">{t(COMMON_TRANSLATE_KEYS.COMPLEX)}:</span>
            <DropdownMenu<Complex | null>
              items={complexMenuOptions}
              selectedOption={selectedComplex}
              setSelectedOption={setSelectedComplex}
              showIcon={false}
              placeholder={t(COMMON_TRANSLATE_KEYS.COMPLEX_SELECT_PLACEHOLDER)}
              transparent
            />
          </div>
          <div>
            <DropdownMenu<string>
              items={statusMenuOptions}
              selectedOption={selectedStatus}
              setSelectedOption={setSelectedStatus}
              showIcon={false}
              width={12}
              placeholder={t(COMMON_TRANSLATE_KEYS.STATUS_SELECT_PLACEHOLDER)}
            />
          </div>
        </div>
        {renderReservationCards()}
      </div>

      <div>
        <div className="relative z-30">
          <ReservationDetailsModal
            reservation={currentReservation}
            closeDrawer={closeDrawer}
            isOpen={isDrawerOpen}
            onPlayerClick={handlePlayerClick}
          />
        </div>
      </div>

      {isSecondDrawerOpen && <PlayerDetailsModal close={closeSecondDrawer} player={currentPlayer} team={currentReservation?.team} />}
    </>
  );
};

export default ReservationPage;
