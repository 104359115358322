import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { COMMON_TRANSLATE_KEYS } from "../../constants/translate-keys";
import { Player } from "../../types/players";
import { Team } from "../../types/team";
import { getPlayerRole } from "../../utils/players";

type TProps = {
  player: Player | null;
  team?: Team;
  close: () => void;
}

const renderPlayerDetail = (label: string, value?: string) =>
  <p>{label}: <span className="text-textGray">{value || ''}</span></p>;

const PlayerDetailsModal = ({
  player,
  team,
  close,
}: TProps) => {
  const { t, i18n } = useTranslation();

  const playerPosition = useMemo(() => 
    i18n.language === 'en'
      ? player?.position?.name
      : player?.position?.nameBg,
    [i18n.language, player?.position?.name, player?.position?.nameBg]);


  if (!player || !team) {
    return <></>;
  }

  return (
    <div
      className="items-center absolute z-50 top-0 right-[391px] bg-secondaryBlack border-r border-backGroundGray p-4 text-white h-full w-[400px]"
      style={{ width: "391px" }}
    >
      <button
        onClick={close}
        className="absolute top-4 right-4"
      >
        X
      </button>
      <div className="bg-additionalBlack rounded-lg mt-16">
        <div>
          <img
            src={player.imageUrl || 'ellipse.png'}
            alt="Player"
            className="object-cover w-full h-[240px] rounded-lg"
          />
        </div>
        <div className="flex flex-col p-3 gap-3">
          <span>
            <h2 className="font-inter text-2xl not-italic font-bold leading-7">
              {player.name}
              {player.age && `, ${player.age}`}
            </h2>
            <p className="text-mainBlue font-semibold">{getPlayerRole(player, team)}</p>
          </span>
          <span>
            {player.nickname && renderPlayerDetail(t(COMMON_TRANSLATE_KEYS.NICKNAME), player.nickname)}
            {player.city && renderPlayerDetail(t(COMMON_TRANSLATE_KEYS.CITY), player.city)}
            {player.phone && renderPlayerDetail(t(COMMON_TRANSLATE_KEYS.PHONE), player.phone)}
            {player.email && renderPlayerDetail(t(COMMON_TRANSLATE_KEYS.EMAIL), player.email)}
            {player.position && renderPlayerDetail(t(COMMON_TRANSLATE_KEYS.POSITION), playerPosition)}
          </span>
          {player.bio && <p className="text-textGray">{player.bio}</p>}
        </div>
      </div>
    </div>
  )
};

export default PlayerDetailsModal;