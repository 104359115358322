import axios, { AxiosError } from "axios";
import { getHeaders } from "./utils/getHeader";

import { Width } from "../types";

type GetWidthsResultWithError = {
  data?: Width[];
  error?: any;
};

export const fetchWidths = async (): Promise<GetWidthsResultWithError> => {
  let result: GetWidthsResultWithError = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/fields/field-width-options`,
      {
        headers: getHeaders(true),
      }
    );

    result.data = response.data as Width[];
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching widths:", error);
    result.error = response?.data;
  }

  return result;
};
