import LinkButton from ".";
import GoogleImg from "../../../icons/svg/google.svg";

const googleLoginLink = `${process.env.REACT_APP_API_ENDPOINT}/auth/owner/google`;

interface ButtonProps {
  width?: number;
  height?: number;
  backgroundColor?: string;
  textColor?: string;
  border?: string;
  isDisabled?: boolean,
}

const GoogleButton = (buttonProps: ButtonProps) =>
  <LinkButton href={googleLoginLink} img={GoogleImg} {...buttonProps} />

export default GoogleButton;