import {
  COMPLEX_FILENAME_DEV_REGEX,
  COMPLEX_FILENAME_STAGING_REGEX,
  FIELD_FILENAME_DEV_REGEX,
  FIELD_FILENAME_STAGING_REGEX
} from "./regex";

const FILENAME_URL_REGEX_PICKER = {
  complex: {
    development: COMPLEX_FILENAME_DEV_REGEX,
    staging: COMPLEX_FILENAME_STAGING_REGEX,
  },
  field: {
    development: FIELD_FILENAME_DEV_REGEX,
    staging: FIELD_FILENAME_STAGING_REGEX,
  }
}

export const toBase64 = (file?: Blob | null): Promise<string> | null => {
  if (!file) {
    return null;
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const getFileNameFromUrl = (url: string, type: 'complex' | 'field') => {
  const environment = process.env.NODE_ENV === 'development' ? 'development' : 'staging';
  const regex = FILENAME_URL_REGEX_PICKER[type][environment];
  
  const match = url.match(regex);
  
  return match ? match[1] : '';
}