import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import DarkenBackground from "../components/DarkenBackground";
import InputField from "../components/InputField";
import Button from "../components/buttons/Button";
import { forgotPassword, verifyCode } from "../api/auth";
import { ROUTE_LOGIN, ROUTE_RESET_PASS } from "../router/constants";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";

interface IFormInput {
  code: string;
}

const ForgotPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [lastSent, setLastSent] = useState<number | null>(
    Number(location.state?.sent || null)
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    defaultValues: {
      code: "",
    },
  });

  const sendResetEmail = useCallback(async () => {
    if (lastSent && Date.now() < lastSent + 5 * 60 * 1000) {
      setError("code", {
        message: "Please wait 5 minutes before sending another email",
      });
      return;
    }

    if (location.state?.email) {
      const payload = {
        email: location.state.email,
      };

      const { data, error } = await forgotPassword(payload);

      if (error) {
        const { error: errMsg } = error;

        if (errMsg === "Owner not found") {
          navigate(`${ROUTE_LOGIN}?error=No owner found with the given email`, {
            replace: true,
          });
          return;
        }

        setError("code", { message: errMsg });
        return;
      }

      if (data) {
        if (!data.success) {
          setError("code", {
            message:
              "It appears that the email was not sent. Please, contact the developer",
          });
          return;
        } else {
          setLastSent(Date.now());
        }
      }
    }
  }, [location.state?.email, setError, navigate, lastSent]);

  const handleCodeSubmit = async (input: IFormInput) => {
    const { code } = input;
    const email = location.state?.email;

    if (!email) {
      navigate(`${ROUTE_LOGIN}?error=${t(COMMON_TRANSLATE_KEYS.UNEXPECTED_ERROR)}`);
      return;
    }

    const payload = {
      code,
      email,
    };

    const { data, error } = await verifyCode(payload);

    if (error) {
      const { error: errMsg } = error;

      setError("code", { message: errMsg });
      return;
    }

    if (!data?.isValid) {
      setError("code", {
        message: t(COMMON_TRANSLATE_KEYS.EXPIRED_CODE),
      });
      return;
    }

    if (data.isValid) {
      navigate(ROUTE_RESET_PASS, { replace: true, state: { email, code } });
    }
  };

  return (
    <div className="relative h-screen flex justify-center ">
      <DarkenBackground />

      <div className="z-20 justify-center w-[386px] flex flex-col items-center">
        <div className="flex flex-col justify-center items-center mb-8">
          <h3 className="text-[30px] text-white font-light font-montserrat">
            {t(COMMON_TRANSLATE_KEYS.ENTER_CODE)}
          </h3>
          <p className="text-gray-500 text-center">{t(COMMON_TRANSLATE_KEYS.RESET_PASS_TEXT, { email: location.state?.email })}</p>
        </div>
        <form
          onSubmit={handleSubmit(handleCodeSubmit)}
          className="flex flex-col justify-center mb-8 gap-4"
        >
          <InputField
            {...register("code", {
              required: true,
              maxLength: 6,
              minLength: 6,
            })}
            error={errors.code?.message}
            placeholder={t(COMMON_TRANSLATE_KEYS.ENTER_CODE)}
            width={24}
          />

          <p className="text-white text-center">
            {t(COMMON_TRANSLATE_KEYS.NO_EMAIL)}?{" "}
            <span
              className="text-mainGreen cursor-pointer"
              onClick={() => sendResetEmail()}
            >
              {t(COMMON_TRANSLATE_KEYS.SEND_AGAIN)}
            </span>
          </p>

          <Button
            type="submit"
            backgroundColor="bg-mainGreen"
            width={24}
            textColor="text-black-300"
            isDisabled={!isValid}
          >
            {t(COMMON_TRANSLATE_KEYS.NEXT)}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
