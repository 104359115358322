import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { getToken } from "../utils/token";
import { Complex } from "../types";
import { fetchComplexes } from "../api/complexes";

type TProps = {
  children: ReactNode;
};

type ComplexesContextValue = {
  complexes: Complex[];
  setComplexes: (complexes: Complex[]) => void;
  getComplexes: () => Promise<void>;
  clearComplexes: () => void;
  error: any;
  fetched: boolean;
};

export const ComplexesContext = createContext<ComplexesContextValue>({
  complexes: [],
  setComplexes: () => undefined,
  getComplexes: async () => undefined,
  clearComplexes: () => undefined,
  error: null,
  fetched: false,
});

const ComplexesProvider = ({ children }: TProps) => {
  const [complexes, setComplexes] = useState<Complex[]>([]);
  const [error, setError] = useState<any>(null);
  const [fetched, setFetched] = useState(false);

  const updateComplexArray = useCallback(async (newComplexes: Complex[]) => {
    setComplexes((prev) => {
      const diffComplexes = newComplexes.filter(c => prev.findIndex(x => x.id === c.id) === -1);

      if (diffComplexes.length === 0) {
        return prev;
      }

      return [...prev, ...diffComplexes];
    });
  }, []);

  const getOwnerComplexes = useCallback(async () => {
    const { data, error } = await fetchComplexes();

    setFetched(true);

    if (error) {
      setError(error.error);
      setComplexes([]);
      return;
    }

    if (data) {
      setComplexes(data);
    }
  }, []);

  const clearComplexes = useCallback(() => {
    setComplexes([]);
    setError(null);
    setFetched(false);
  }, []);

  useEffect(() => {
    const tokenInStorage = getToken();

    if (tokenInStorage && !fetched) {
      getOwnerComplexes()
    }
  }, [fetched, getOwnerComplexes]);

  const value = useMemo(
    () => ({
      complexes,
      error,
      fetched,
      setComplexes: updateComplexArray,
      getComplexes: getOwnerComplexes,
      clearComplexes,
    }),
    [complexes, updateComplexArray, getOwnerComplexes, clearComplexes, error, fetched]
  );

  return <ComplexesContext.Provider value={value}>{children}</ComplexesContext.Provider>;
};

export default ComplexesProvider;
