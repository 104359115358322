import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "./useAuth";
import { useComplexes } from "./useComplexes";
import { ROUTE_LOGIN } from "../router/constants";
import { useNotifications } from "./notifications/useNotifications";
import { useUnreadNotifications } from "./notifications/useUnreadNotifications";
import { logout as logoutApiCallback } from "../api/auth";
import { clearMsgToken, getMsgToken } from "../utils/token";

export const useLogout = () => {
  const { logoutUser } = useAuth();
  const { clearComplexes } = useComplexes();
  const { clearNotifications } = useNotifications();
  const { resetUnreadNotificationsCount } = useUnreadNotifications();

  const navigate = useNavigate();

  // Call context reset callbacks here
  const resetContext = useCallback(() => {
    clearComplexes();
    logoutUser();
    clearNotifications();
    resetUnreadNotificationsCount();
  }, [logoutUser, clearComplexes, clearNotifications, resetUnreadNotificationsCount]);

  const logout = useCallback(async () => {
    const { error } = await logoutApiCallback({
      token: getMsgToken(),
    });

    if (error) {
      toast(error, {
        toastId: 'accept-res-error',
        theme: 'dark',
        bodyClassName: 'text-center'
      });
      return;
    }

    clearMsgToken();

    resetContext();

    navigate(ROUTE_LOGIN, { replace: true });
  }, [resetContext, navigate]);

  return { logout, reset: resetContext };
};
