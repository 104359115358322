import React, { useEffect } from "react";
import ComplexCard from "../components/cards/ComplexCard";
import Button from "../components/buttons/Button";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTE_UPDATE_ARENA } from "../router/constants";
import { useComplexes } from "../hooks/useComplexes";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import { useTranslation } from "react-i18next";

const MyFields = () => {
  const { getComplexes, complexes } = useComplexes();

  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    getComplexes();
  }, [getComplexes]);

  const handleSeeDetails = (arenaId: string) => {
    navigate(`/arena/${arenaId}/info`);
  };

  const onUpdateArena = (id: string | number) => {
    const id_param = id.toString();

    navigate(generatePath(ROUTE_UPDATE_ARENA, { id: id_param }));
  };

  return (
    <div className="ml-8">
      <h1 className="text-white my-4">{t(COMMON_TRANSLATE_KEYS.COMPLEXES)}</h1>
      <div className="flex flex-wrap gap-4">
        {complexes.map((complex: any) => {
          return (
            <ComplexCard
              key={complex.id}
              title={complex.name}
              location={`${complex.city}, ${complex.country}`}
              width="lg"
              complex={complex}
            >
              <div className="flex flex-row justify-between items-center">
                <Button
                  border="border-mainBlue"
                  backgroundColor="bg-gray-800"
                  width={17}
                  textColor="text-mainBlue"
                  onClick={() => handleSeeDetails(complex.id)}
                >
                  {t(COMMON_TRANSLATE_KEYS.SEE_DETAILS)}
                </Button>

                <Button
                  backgroundColor="bg-functionalGreen"
                  width={17}
                  textColor="text-gray-800"
                  onClick={() => onUpdateArena(complex.id)}
                >
                  {t(COMMON_TRANSLATE_KEYS.ADD_FIELD)}
                </Button>
              </div>
            </ComplexCard>
          );
        })}
        {complexes.length === 0 && (
          <h1 className="text-textGray font-normal text-xl text-center">No complexes. Create one by clicking on the "Create Complex" button above</h1>
        )}
      </div>
    </div>
  );
};

export default MyFields;
