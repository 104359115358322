import React, { useCallback, useMemo, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import CalendarHeader from "./CalendarHeader";
import CalendarContainer from "./CalendarContainer";

import "./DateCalendarStyles.css";

import { enUS } from "date-fns/locale/en-US";
import { bg } from "date-fns/locale/bg";

registerLocale('en-US', enUS);
registerLocale('bg', bg);

type TProps = {
  onSelectDate: (date: Date) => void;
  onMonthDateChange: (month: number, year: number) => Promise<void>;
  filteredDates?: Date[];
  openToDate?: Date;
  selectedDate: Date | null;
}

const DateGridCalendar = ({
  onSelectDate,
  filteredDates,
  onMonthDateChange,
  openToDate,
  selectedDate: parentDate,
}: TProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(parentDate || null);

  const { i18n } = useTranslation();

  const locale = useMemo(() => i18n.language, [i18n.language]);

  const handleMonthOrYearChange = useCallback(async (date: Date) => {
    const month = date.getMonth();
    const year = date.getFullYear();

    await onMonthDateChange(month, year);
  }, [onMonthDateChange]);

  const getDayClassName = (day: Date) => {
    let defaultClassName = 'flex !w-9 !my-2 !text-mainWhite';

    return defaultClassName;
  }

  const handleDateSelect = (date: Date | null, event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> | undefined) => {
    if (!date || event?.currentTarget.className.includes("react-datepicker__day--outside-month")) {
      return;
    }
    setSelectedDate(date);
  };

  const handleDateSave = (date: Date | null) => {
    if (!date) {
      return;
    }

    onSelectDate(date);
  }

  return (
    <DatePicker
      locale={locale}
      renderCustomHeader={(props) => <CalendarHeader {...props} />}
      calendarContainer={(props) =>
        <CalendarContainer
          {...props}
          date={selectedDate}
          onSave={() => handleDateSave(selectedDate)}
          isButtonDisabled={!selectedDate}
        />
      }
      formatWeekDay={(day) => day.slice(0, 3).toUpperCase()}
      calendarClassName="text-mainWhite shadow-lg !rounded-xl !bg-backGroundGray !border-0 !flex !flex-col"
      weekDayClassName={() => "flex !w-9 !text-textGray !text-xs !mt-4 opacity-50"}
      dayClassName={getDayClassName}
      selected={selectedDate}
      openToDate={openToDate}
      onChange={handleDateSelect}
      onMonthChange={handleMonthOrYearChange}
      onYearChange={handleMonthOrYearChange}
      filterDate={filteredDates
        ? (day) => filteredDates.some((d) => dayjs(d).isSame(day, 'date'))
        : undefined
      }
      inline
    />
  );

};

export default DateGridCalendar;
