import { createContext, ReactNode, useCallback, useMemo, useState } from "react";

import { Currency } from "../types";
import { fetchCurrencies } from "../api/currencies";

type TProps = {
  children: ReactNode;
};

type CurrenciesContextValue = {
  currencies: Currency[];
  setCurrencies: (complexes: Currency[]) => void;
  getCurrencies: () => Promise<void>;
  clearCurrencies: () => void;
  error: any;
  fetched: boolean;
};

export const CurrenciesContext = createContext<CurrenciesContextValue>({
  currencies: [],
  setCurrencies: () => undefined,
  getCurrencies: async () => undefined,
  clearCurrencies: () => undefined,
  error: null,
  fetched: false,
});

const CurrenciesProvider = ({ children }: TProps) => {
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [error, setError] = useState<any>(null);
  const [fetched, setFetched] = useState(false);

  const updateCurrenciesArray = useCallback(async (newCurrencies: Currency[]) => {
    setCurrencies((prev) => {
      const diffComplexes = newCurrencies.filter(c => prev.findIndex(x => x.id === c.id) === -1);

      if (diffComplexes.length === 0) {
        return prev;
      }

      return [...prev, ...diffComplexes];
    });
  }, []);

  const getCurrencies = useCallback(async () => {
    const { data, error } = await fetchCurrencies();

    setFetched(true);

    if (error) {
      setError(error.error);
      setCurrencies([]);
      return;
    }

    if (data) {
      setCurrencies(data);
    }
  }, []);

  const clearCurrencies = useCallback(() => {
    setCurrencies([]);
    setError(null);
    setFetched(false);
  }, []);

  const value = useMemo(
    () => ({
      currencies,
      error,
      fetched,
      setCurrencies: updateCurrenciesArray,
      getCurrencies,
      clearCurrencies,
    }),
    [currencies, updateCurrenciesArray, getCurrencies, clearCurrencies, error, fetched]
  );

  return <CurrenciesContext.Provider value={value}>{children}</CurrenciesContext.Provider>;
};

export default CurrenciesProvider;
