import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { useTranslation } from "react-i18next";
import range from 'lodash/range';
import dayjs from "dayjs";

const years = range(dayjs().year(), dayjs().add(10, 'year').year(), 1);

const CalendarHeader = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) => {
  const { i18n } = useTranslation();

  return (
    <div className="flex flex-row justify-between w-full px-3 text-mainWhite items-center text-base mt-3">
      <div className="flex flex-row gap-1">
        <span>{dayjs(date).locale(i18n.language).format('MMMM')}</span>
        <select
          value={dayjs(date).year()}
          onChange={({ target: { value } }) => changeYear(Number(value))}
          className="bg-transparent"
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      
      <div className="flex flex-row gap-3">
        <ChevronLeftIcon
          className={`size-6 cursor-pointer text-white ${prevMonthButtonDisabled ? 'opacity-50' : 'opacity-100'}`}
          onClick={() => !prevMonthButtonDisabled && decreaseMonth()}
        />
        <ChevronRightIcon
          className={`size-6 cursor-pointer text-white ${nextMonthButtonDisabled ? 'opacity-50' : 'opacity-100'}`}
          onClick={() => !nextMonthButtonDisabled && increaseMonth()}
        />
      </div>
    </div>
  );
};

export default CalendarHeader;
