import axios, { AxiosError } from "axios";
import { getHeaders } from "./utils/getHeader";
import { GetFlooringTypesResult } from "../types/flooring-types";

type GetCurrenciesResultWithError = {
  data?: GetFlooringTypesResult;
  error?: any;
};

export const getFlooringTypes = async (page = 1, limit = 20): Promise<GetCurrenciesResultWithError> => {
  let result: GetCurrenciesResultWithError = {};

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/fields/flooring-types?page=${page}&limit=${limit}`,
      {
        headers: getHeaders(true),
      }
    );

    result.data = response.data as GetFlooringTypesResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching flooring types:", error);
    result.error = response?.data;
  }

  return result;
}