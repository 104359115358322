import { t } from "i18next";

import { Player } from "../types/players";
import { Team } from "../types/team";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";

export const getPlayerRole = (player: Player, team: Team) => {
  if (team.captain.id === player.id) {
    return t(COMMON_TRANSLATE_KEYS.CAPTAIN);
  } else if (team.secondCaptain?.id === player.id) {
    return t(COMMON_TRANSLATE_KEYS.SECOND_CAPTAIN);
  } else {
    return t(COMMON_TRANSLATE_KEYS.PLAYERS, { count: 1 });
  }
};
