import { createContext, ReactNode, useCallback, useEffect, useMemo } from "react";
import { getToken } from "firebase/messaging";

import { messaging } from "../services/firebase";
import { subscribeForNotifications as subscribeApiCallback } from "../api/notifications";
import { clearMsgToken, setMsgToken } from "../utils/token";
import { toast } from "react-toastify";

type TProps = {
  children: ReactNode;
};

type FirebaseMessagingContextValue = {
  initializeNotifications: () => Promise<void>;
  subscribeForNotifications: () => Promise<void>;
};

export const FirebaseMessagingContext = createContext<FirebaseMessagingContextValue>({
  initializeNotifications: async () => undefined,
  subscribeForNotifications: async () => undefined,
});

const FirebaseMessagingProvider = ({ children }: TProps) => {
  const subscribeForNotifications = useCallback(async () => {
    const permission = Notification.permission;

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: 'BLqeBXCg0gjA16v6Oh02C2X1VKhDHA_fXM_zYuKPY4tb0OQ4OxjDXVHa545A1ahxJS491cOzChLn5NWSdBT2hRI',
      });

      const { data, error } = await subscribeApiCallback(token);

      if (error) {
        console.log('Error subscribing for notifications: ', error);
        return;
      }

      if (data?.success) {
        console.log('Success subscribing for notifications');
        setMsgToken(token);
      } 
    } else {
      // Clear if present
      clearMsgToken();
    }
  }, []);

  const initializeNotifications = useCallback(async () => {
    try {
      const permission = await Notification.requestPermission();

      if (permission === "denied") {
        toast("You denied permission for notifications", {
          type: "error",
          autoClose: 3000,
        });
        return;
      }

      await subscribeForNotifications();
    } catch (error) {
      console.log('Error initializing notifications: ', error);
      clearMsgToken();
    }
  }, [subscribeForNotifications]);

  useEffect(() => {
    subscribeForNotifications();
  }, [subscribeForNotifications]);

  const value = useMemo(
    () => ({
      initializeNotifications,
      subscribeForNotifications,
    }),
    [initializeNotifications, subscribeForNotifications]
  );

  return <FirebaseMessagingContext.Provider value={value}>{children}</FirebaseMessagingContext.Provider>;
};

export default FirebaseMessagingProvider;
