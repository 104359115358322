import axios, { AxiosError } from "axios";

import {
  DeclineReservationPayload,
  GetAvailableReservationSlotsResult,
  GetComplexReservationsResult,
  Reservation,
} from "../types/reservations";
import { getHeaders } from "./utils/getHeader";

type AcceptReservationResultWithError = {
  data?: Reservation;
  error?: any;
};

type DeclineReservationResultWithError = {
  data?: Reservation;
  error?: any;
};

type GetComplexReservationsWithError = {
  data?: GetComplexReservationsResult;
  error?: any;
};

type GetAvailableReservationSlotsResultWithError = {
  data?: GetAvailableReservationSlotsResult;
  error?: any;
}

type GetFieldReservationsResultWithError = {
  data?: Reservation[];
  error?: any;
};

export const getComplexReservations = async (
  complexId: number,
  page = 1,
  limit = 50,
  status?: string,
  excludeExpired?: boolean,
  pendingFirst?: boolean,
): Promise<GetComplexReservationsWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${complexId}/reservations?page=${page}&limit=${limit}`;

  let result: GetComplexReservationsWithError = {};

  try {
    const response = await axios.get(url, {
      headers: getHeaders(true),
      params: {
        status,
        excludeExpired,
        pendingFirst,
      },
    });

    result.data = response.data as GetComplexReservationsResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching complexes:", error);
    result.error = response?.data;
  }

  return result;
};

export const acceptReservation = async (
  reservationId: number
): Promise<AcceptReservationResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/reservations/${reservationId}/approve`;

  let result: AcceptReservationResultWithError = {};

  try {
    const response = await axios.put(
      url,
      {},
      {
        headers: getHeaders(true),
      }
    );

    result.data = response.data as Reservation;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error calling: ", url);
    result.error = response?.data;
  }

  return result;
};

export const declineReservation = async (
  reservationId: number,
  payload: DeclineReservationPayload
): Promise<DeclineReservationResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/reservations/${reservationId}/decline`;

  let result: DeclineReservationResultWithError = {};

  try {
    const response = await axios.put(url, payload, {
      headers: getHeaders(true),
    });

    result.data = response.data as Reservation;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error calling: ", url);
    result.error = response?.data;
  }

  return result;
}

export const fetchAvailableReservationSlots = async (
  fieldId: number,
  month: number,
  year: number,
): Promise<GetAvailableReservationSlotsResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/reservations/available-dates?fieldId=${fieldId}&month=${month}&year=${year}`;

  let result: GetAvailableReservationSlotsResultWithError = {};

  try {
    const response = await axios.get(
      url,
      {
        headers: getHeaders(true),
      }
    );

    result.data = response.data as GetAvailableReservationSlotsResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching reservation slots:", error);
    result.error = response?.data;
  }

  return result;
};

export const getReservationsByDatePeriod = async (complexId: number, fieldId: number, startDate: Date, endDate: Date): Promise<GetFieldReservationsResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${complexId}/fields/${fieldId}/reservations?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;

  let result: GetFieldReservationsResultWithError = {};

  try {
    const response = await axios.get(url, { headers: getHeaders(true) });

    result.data = response.data as Reservation[];
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error fetching fields reservations:", error);
    result.error = response?.data;
  }

  return result;
}
