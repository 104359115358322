import { getToken } from "../../utils/token";
import { getLanguage } from "../../utils/language";
import { AxiosHeaders } from "axios";

export const getHeaders = (shouldAuthenticate = false, token?: string): AxiosHeaders => {
  const headers: AxiosHeaders = new AxiosHeaders().set("Accept-Language", getLanguage());

  if (shouldAuthenticate) {
    const accessToken = token || getToken();
    if (accessToken) {
      headers.setAuthorization(`Bearer ${accessToken}`);
    }
  }

  return headers;
};
