import { useCallback } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import ComplexSettingsCard from "../../../components/cards/ComplexSettingsCard"
import { useComplexes } from "../../../hooks/useComplexes";
import { deleteComplex } from "../../../api/complexes";
import { COMMON_TRANSLATE_KEYS } from "../../../constants/translate-keys";

const MyFieldsTab = () => {
  const { complexes, getComplexes } = useComplexes();

  const { t } = useTranslation();

  const handleDeleteComplex = useCallback(async (complexId: number) => {
    const { data, error } = await deleteComplex(complexId);

    if (error) {
      toast(error.error.message, {
        toastId: 'update-owner',
      });
      return;
    }

    if (data) {
      await getComplexes();
    }
  }, [getComplexes]);

  const renderComplexCards = useCallback(() => {
    if (complexes.length === 0) {
      return (
        <h3>{t(COMMON_TRANSLATE_KEYS.NO_COMPLEXES)}</h3>
      );
    }

    return complexes.map((c) => (
      <ComplexSettingsCard key={c.id} complex={c} handleDeleteComplex={handleDeleteComplex} />
    ));
  }, [complexes, handleDeleteComplex, t]);

  return (
    <div className="flex flex-row gap-4 my-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-4/6">
        {renderComplexCards()}
      </div>
    </div>
  );
};

export default MyFieldsTab;
