import React from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

interface DrawerProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

const MyDrawer: React.FC<DrawerProps> = ({ isOpen, onClose, children }) => {
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="right"
      style={{ width: "391px", backgroundColor: "#2B2B2D", height: "100%", overflow: "auto" }}
    >
      <div className="bg-additionalBlack rounded-sm text-white ml-[24px] mr-[39px]">
        {children}
      </div>
    </Drawer>
  );
};

export default MyDrawer;
