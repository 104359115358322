import { useMemo } from "react";
import Carousel, { ResponsiveType } from "react-multi-carousel";
import { TImage } from "../../types";

import './ImageCarousel.css';

type TProps = {
  images: TImage[];
  readonly?: boolean;
  onRemoveItem?: (image: TImage) => void;
  itemClassName?: string;
  containerClassName?: string;
  itemsShown?: { superLargeDesktop?: number, desktop?: number, tablet?: number, mobile?: number };
  showDots?: boolean;
}

const ImageCarousel = ({
  images,
  onRemoveItem,
  readonly,
  containerClassName,
  itemsShown,
  itemClassName = '',
  showDots = true,
}: TProps) => {
  const responsive = useMemo<ResponsiveType>(() => {
    return {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: itemsShown?.superLargeDesktop || 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: itemsShown?.desktop || 2
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: itemsShown?.tablet || 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: itemsShown?.mobile || 1
      }
    };
  }, [itemsShown?.desktop, itemsShown?.mobile, itemsShown?.superLargeDesktop, itemsShown?.tablet]);

  return (
    <Carousel
      responsive={responsive}
      slidesToSlide={1}
      className="w-full"
      itemClass="w-auto"
      containerClass={containerClassName}
      showDots={showDots}
      arrows
    >
      {images.map((i) => (
        <div key={i.id} className={"relative " + itemClassName}>
          {!readonly && <button onClick={() => onRemoveItem && onRemoveItem(i)} className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-[25px] h-[26px]">x</button>}
          <img className="object-cover h-full w-full rounded-md" src={i.src} alt={i.id} />
        </div>
      ))}
    </Carousel>
  )
};

export default ImageCarousel;