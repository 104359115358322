import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { FlooringType, MenuItem } from "../../types";
import DropdownMenu from "../DropdownMenu";
import { useFlooringTypes } from "../../hooks/useFlooringTypes";

type TProps = {
  selectedFlooring: MenuItem<FlooringType> | null;
  setSelectedFlooring: Function;
  showIcon?: boolean;
  width?: number;
  height?: number;
  placeholder?: string;
  transparent?: boolean;
  customLabel?: string; 
  isIconAdjacent?: boolean;
  isIconActive?: boolean;
  error?: string;
};

const FlooringTypesDropdown = ({
  selectedFlooring,
  setSelectedFlooring,
  ...rest
}: TProps) => {
  const { flooringTypes, error, getFlooringTypes } = useFlooringTypes();

  const { i18n } = useTranslation();

  const options = useMemo(() => {
    return flooringTypes.map((c) => {
      const item = {
        label: i18n.language === "en" ? c.name : c.nameBg,
        value: c,
        onClick: () => undefined,
      };

      item.onClick = () => setSelectedFlooring(item);

      return item;
    })
  }, [flooringTypes, setSelectedFlooring, i18n.language]);

  const selectedOption = useMemo(() => {
    if (!selectedFlooring) {
      return null;
    }

    const selected = { ...selectedFlooring };

    const label = i18n.language === "en"
      ? selectedFlooring.value.name
      : selectedFlooring.value.nameBg;

    selected.label = label;

    return selected;
  }, [selectedFlooring, i18n.language]);

  useEffect(() => {
    getFlooringTypes();
  }, [getFlooringTypes]);

  useEffect(() => {
    if (error) {
      toast(error.error, {
        toastId: 'fetch-floorings-error',
        theme: "dark",
        className: "text-center",
        bodyClassName: "custom-toast-body",
      });
    }
  }, [error]);

  return (
    <DropdownMenu<FlooringType>
      items={options}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedFlooring}
      {...rest}
    />
  )
};

export default FlooringTypesDropdown;