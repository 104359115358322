import axios, { AxiosError } from "axios";

import { ForgotPasswordPayload, ForgotPasswordResult, LoginPayload, LoginResult, LogoutPayload, LogoutResult, RegisterPayload, RegisterResult, ResetPasswordPayload, ResetPasswordResult, VerifyForgotPassCodePayload, VerifyForgotPassCodeResult } from "../types/auth";
import { getHeaders } from "./utils/getHeader";

type LoginResultWithError = {
  data?: LoginResult;
  error?: any;
}

type LogoutResultWithError = {
  data?: LogoutResult;
  error?: any;
}

type RegisterResultWithError = {
  data?: RegisterResult;
  error?: any;
}

type ForgotPassResultWithError = {
  data?: ForgotPasswordResult;
  error?: any;
}

type VerifyForgotPassCodeResultWithError = {
  data?: VerifyForgotPassCodeResult;
  error?: any;
}

type ResetPasswordResultWithError = {
  data?: ResetPasswordResult;
  error?: any;
}

export const login = async (payload: LoginPayload): Promise<LoginResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/owner/login`;

  let result: LoginResultWithError = {};

  try {
    const response = await axios.post(
      url,
      payload,
      {
        headers: getHeaders(),
      }
    );

    result.data = response.data as LoginResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error calling: ", url);
    result.error = response?.data;
  }

  return result;
}

export const register = async (payload: RegisterPayload): Promise<RegisterResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/owner/register`;

  let result: RegisterResultWithError = {};

  try {
    const response = await axios.post(
      url,
      payload,
      {
        headers: getHeaders(),
      },
    );

    result.data = response.data as RegisterResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error calling: ", url);
    result.error = response?.data;
  }

  return result;
}

export const forgotPassword = async (payload: ForgotPasswordPayload): Promise<ForgotPassResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/owner/reset-password`;

  let result: ForgotPassResultWithError = {};

  try {
    const response = await axios.post(
      url,
      payload,
      {
        headers: getHeaders(),
      },
    );

    result.data = response.data as ForgotPasswordResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error calling: ", url);
    result.error = response?.data;
  }

  return result;
}

export const verifyCode = async (payload: VerifyForgotPassCodePayload): Promise<VerifyForgotPassCodeResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/reset-password/code`;

  let result: VerifyForgotPassCodeResultWithError = {};

  try {
    const response = await axios.post(
      url,
      payload,
      {
        headers: getHeaders(),
      },
    );

    result.data = response.data as VerifyForgotPassCodeResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error calling: ", url);
    result.error = response?.data;
  }

  return result;
}

export const resetPassword = async (payload: ResetPasswordPayload): Promise<ResetPasswordResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/reset-password`;

  let result: ResetPasswordResultWithError = {};

  try {
    const response = await axios.put(
      url,
      payload,
      {
        headers: getHeaders(),
      },
    );

    result.data = response.data as ResetPasswordResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error calling: ", url);
    result.error = response?.data;
  }

  return result;
}

export const logout = async (payload: LogoutPayload): Promise<LogoutResultWithError> => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/owner/logout`;

  let result: LogoutResultWithError = {};

  try {
    const response = await axios.put(
      url,
      payload,
      {
        headers: getHeaders(true),
      }
    );

    result.data = response.data as LogoutResult;
  } catch (error) {
    const { response } = error as AxiosError;
    console.error("Error calling: ", url);
    result.error = response?.data;
  }

  return result;
}