import { useContext } from "react";
import { WidthContext } from "../contexts/WidthContext";

export const useWidth = () => {
  const context = useContext(WidthContext);
  if (!context) {
    throw new Error("useWidth must be used within a WidthProvider");
  }
  return context;
};
