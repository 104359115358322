import { createContext, useState, useEffect, ReactNode } from "react";
import { fetchWidths } from "../api/width";
import { Width } from "../types";

interface WidthContextType {
  widths: Width[];
  error: any;
  getWidths: () => void;
}

export const WidthContext = createContext<WidthContextType | undefined>(
  undefined
);

export const WidthProvider = ({ children }: { children: ReactNode }) => {
  const [widths, setWidths] = useState<Width[]>([]);
  const [error, setError] = useState<any>(null);

  const getWidths = async () => {
    const result = await fetchWidths();
    if (result.error) {
      setError(result.error);
    } else {
      setWidths(result.data ?? []);
    }
  };

  useEffect(() => {
    getWidths();
  }, []);

  return (
    <WidthContext.Provider value={{ widths, error, getWidths }}>
      {children}
    </WidthContext.Provider>
  );
};
