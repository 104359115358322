import React, { forwardRef } from "react";

interface DescriptionInputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  placeholder?: string;
  value?: string;
  width?: number;
  height?: number;
  error?: string;
}

const DescriptionInput: React.FC<DescriptionInputProps> = forwardRef<HTMLTextAreaElement, DescriptionInputProps>(({
  placeholder,
  value,
  onChange,
  width,
  height = 10,
  error,
  disabled,
  ...rest
}, ref) => {
  const getInputClassName = () => {
    let className = 'relative rounded-2xl text-sm p-4 placeholder-mainBlue'; 
    if (error) {
      className = className + ' border-cancel border';
    }

    if (disabled) {
      className = className + ' bg-gray-800 text-gray-400';
    } else {
      className = className + ' bg-backGroundGray text-mainBlue';
    }

    return className;
  }

  return (
    <div>
      <textarea
        ref={ref}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={getInputClassName()}
        style={{ width: `${width}rem`, height: `${height}rem`, resize: 'none' }}
        disabled={disabled}
        {...rest}
      />
      {error && <span className="text-cancel font-inter text-[12px] leading-5 w-full">{error}</span>}
    </div>
  );
});

export default DescriptionInput;
