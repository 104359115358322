import { yupResolver } from "@hookform/resolvers/yup";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ROUTE_LOGIN } from "../router/constants";
import DarkenBackground from "../components/DarkenBackground";
import InputField from "../components/InputField";
import Button from "../components/buttons/Button";
import { resetPassSchema } from "../validation/schemas/reset-pass";
import { resetPassword } from "../api/auth";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";

interface IFormInput {
  password: string;
  repeatPassword: string;
}

const ResetPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const { t } = useTranslation();

  const { register, handleSubmit, setError, formState: { errors, isValid } } = useForm<IFormInput>({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
    resolver: yupResolver(resetPassSchema),
    mode: 'onBlur',
  });

  const handlePasswordReset = async (input: IFormInput) => {
    const { password } = input;

    const { email, code } = location.state;

    const payload = {
      password,
      code,
      email,
    };

    const { data, error } = await resetPassword(
      payload,
    );

    if (error) {
      const { error: errMsg } = error;

      setError('password', { message: errMsg });
      return;
    }

    if (!data?.success) {
      setError('repeatPassword', { message: t(COMMON_TRANSLATE_KEYS.EXPIRED_CODE) });
      return;
    }

    if (data.success) {
      navigate(ROUTE_LOGIN, { replace: true, state: { email } });
    }
  }

  if (!location.state?.code || !location.state?.email) {
    return <Navigate to={ROUTE_LOGIN} replace />
  }

  return (
    <div className="relative h-screen flex justify-center ">
      <DarkenBackground />
      
      <div className="z-20 justify-center w-[386px] flex flex-col items-center">
        <div className="flex flex-col justify-center items-center mb-8">
          <h3 className="text-[30px] text-white font-light font-montserrat">
            {t(COMMON_TRANSLATE_KEYS.RESET_PASSWORD)}
          </h3>
          <p className="text-gray-500 text-center">{t(COMMON_TRANSLATE_KEYS.ENTER_NEW_PASS_TEXT)}</p>
        </div>
        <form onSubmit={handleSubmit(handlePasswordReset)} className="flex flex-col justify-center mb-8 gap-4">
          <InputField
            {...register('password')}
            error={t(errors.password?.message || '')}
            placeholder={t(COMMON_TRANSLATE_KEYS.PASSWORD)}
            type="password"
            autoComplete="new-password"
            width={24}
          />
          <InputField
            {...register('repeatPassword')}
            error={t(errors.repeatPassword?.message || '')}
            placeholder={t(COMMON_TRANSLATE_KEYS.REPEAT_PASSWORD)}
            type="password"
            width={24}
          />

          <Button
            type="submit"
            backgroundColor="bg-mainGreen"
            width={24}
            textColor="text-black-300"
            isDisabled={!isValid}
          >
            {t(COMMON_TRANSLATE_KEYS.RESET)}
          </Button>
        </form>
      </div>
    </div>
  )
};

export default ResetPasswordPage;
