import { createContext, ReactNode, useCallback, useMemo, useState } from "react";
import { fetchPrices } from "../api/pricePerHour";
import { PricePerHour } from "../types";

type TProps = {
  children: ReactNode;
};

type PricePerHourContextValue = {
  prices: PricePerHour[];
  setPrices: (prices: PricePerHour[]) => void;
  getPrices: () => Promise<void>;
  clearPrices: () => void;
  error: any;
  fetched: boolean;
};

export const PricePerHourContext = createContext<PricePerHourContextValue>({
  prices: [],
  setPrices: () => undefined,
  getPrices: async () => undefined,
  clearPrices: () => undefined,
  error: null,
  fetched: false,
});

const PricePerHourProvider = ({ children }: TProps) => {
  const [prices, setPrices] = useState<PricePerHour[]>([]);
  const [error, setError] = useState<any>(null);
  const [fetched, setFetched] = useState(false);

  const updatePricesArray = useCallback(async (newPrices: PricePerHour[]) => {
    console.log("Received newPrices:", newPrices);
    setPrices((prev) => {
      const diffPrices = newPrices.filter(p => prev.findIndex(x => x.id === p.id) === -1);
      if (diffPrices.length === 0) {
        return prev;
      }
      return [...prev, ...diffPrices];
    });
  }, []);

  const getPrices = useCallback(async () => {
    const { data, error } = await fetchPrices();
    setFetched(true);
    if (error) {
      setError(error.error);
      setPrices([]);
      return;
    }
    if (data) {
      setPrices(data);
    }
  }, []);

  const clearPrices = useCallback(() => {
    setPrices([]);
    setError(null);
    setFetched(false);
  }, []);

  const value = useMemo(
    () => ({
      prices,
      error,
      fetched,
      setPrices: updatePricesArray,
      getPrices,
      clearPrices,
    }),
    [prices, updatePricesArray, getPrices, clearPrices, error, fetched]
  );

  return <PricePerHourContext.Provider value={value}>{children}</PricePerHourContext.Provider>;
};

export default PricePerHourProvider;
